import { CustomerResponse, GiftStatus } from "../dto/response/customer";

export type CustomerModel = CustomerResponse;

export interface LearningProgressModel {
  lessonProgressId: number;

  customerId: number;

  courseName: string;

  lessonNumber: number;

  firstActivityDate: number;

  lastActivityDate: number;

  isCompleted: boolean;

  listenTimeMinutes: number;

  sourcePlatform: string;

  subUserNames: string[];
}

export interface InAppPurchaseRecordModel {
  id: number;

  isbn: string;

  productName: string;

  isSubscription: boolean;

  status: string;

  source: string;

  expirationDate: number;

  createdDate: number;
}

export interface GiftCardBalanceFieldValue {
  [key: string]: any;
}

export enum CustomerDetailsTab {
  CustomerInfo = "Customer info",
  Transactions = "Transactions",
  Subscriptions = "Subscriptions",
  LearningProgress = "Learning progress",
  InAppPurchaseRecord = "In-app purchase record",
  Gift = "Gift",
}

export interface GiftCardBalanceCurrencyItem {
  currency: string;
  amount: number;
}

export interface GiftModal {
  givenList: GivenGiftListItem[];
  receivedList: ReceivedGiftListItem[];
}

export interface GivenGiftListItem {
  id: string;
  title: string;
  deliveryDate: string;
  recipient: GiftUserInfo;
  status: GiftStatus;
  redeemedEmail?: string;
}

export interface ReceivedGiftListItem {
  id: string;
  title: string;
  redeemDate?: string;
  subscriptionStartDate?: string;
  subscriptionEndDate?: string;
  giver: GiftUserInfo;
  status: GiftStatus;
  redeemedEmail?: string;
}

export interface GiftUserInfo {
  firstName: string;
  lastName: string;
  email: string;
}

export interface CustomerSubscriberInfo {
  subscriberStatus: "Yes" | "No" | "No Data";
}
