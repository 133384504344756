import React, { ChangeEvent, useEffect, useState } from "react";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import { Input, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import omit from "lodash/omit";
import { TableProps } from "antd/es/table";
import { SorterResult } from "antd/lib/table/interface";
import { SortOrder } from "antd/es/table/interface";
import ResizableTitle from "../../../../components/ResizableTitle";
import { EComProductLogListModel, ProductLogModel } from "../../../../types/model/eComProduct";
import { getTotalDisplay } from "../../../../utils/getTotalDisplay";
import { fetchProductLogListById } from "../../../../services/eComProduct";
import { getOffset } from "../../../../utils/getPagination";
import { tableColumnConfig } from "./tableColumnConfig";
import { updateSortOrder } from "../../../../types/transformers/updateSortOrder";
import * as productAPI from "../../../../services/product";
import { ProductDetailResponse } from "../../../../types/dto/response/product";
import useResizableTableHeader from "../../../../hooks/useResizableTableHeader";

interface Props {
  productId: string;
}

export default function EComProductLogTable({ productId }: Props) {
  const [query, setQuery] = useQueryParams({
    pid: withDefault(StringParam, undefined),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 10),
    order: withDefault(StringParam, "descend"),
  });

  const [pid, setPid] = useState(query.pid);
  const [isLoading, setIsLoading] = useState(false);
  const [productInfo, setProductInfo] = useState<EComProductLogListModel>({
    total: 1,
    list: [],
  });
  const columnsToDisplay = useResizableTableHeader<ProductLogModel>(tableColumnConfig(query.order as SortOrder));

  useEffect(() => {
    refreshList();
  }, [query]);

  function getProductLabel(productResponse: ProductDetailResponse) {
    let productLabel = "-";
    if (productResponse.productType === "SINGLE_LEVEL" && productResponse.course) {
      productLabel =
        productResponse.isbn && productResponse.course.courseName
          ? `${productResponse.isbn} ${productResponse.course.courseName}`
          : "-";
    } else if (["MULTI_LEVEL", "ALL_ACCESS", "UPGRADE", "SUBSCRIPTION"].includes(productResponse.productType)) {
      productLabel =
        productResponse.isbn && productResponse.name ? `${productResponse.isbn} ${productResponse.name}` : "-";
    }
    return productLabel;
  }

  function updateLogWithProductInfo(
    logValue: any,
    contentProducts: {
      contentProductId: string;
      contentProductIdValue: string;
    }[]
  ) {
    if (logValue && logValue._content_product_id) {
      const contentProduct = contentProducts.find(
        (product) => product.contentProductId === logValue._content_product_id
      );
      logValue._content_product_id = contentProduct?.contentProductIdValue;
    }
  }

  function refreshList() {
    setIsLoading(true);
    fetchProductLogListById(productId, {
      ...omit(query, ["page"]),
      order: updateSortOrder[query.order as string],
      offset: getOffset(query.page, query.limit),
    })
      .then((data) => {
        const contentProductIds = extractAndDeduplicateContentProductIds(data);
        const fetchProductPromises = contentProductIds.map((id) => {
          return productAPI.fetchProduct(id).then((productResponse) => {
            const productLabel = getProductLabel(productResponse);
            return {
              contentProductId: id,
              contentProductIdValue: productLabel,
            };
          });
        });

        Promise.all(fetchProductPromises).then((contentProducts) => {
          data.list.forEach((log) => {
            updateLogWithProductInfo(log.prevValue, contentProducts);
            updateLogWithProductInfo(log.currentValue, contentProducts);
          });
          setProductInfo(data);
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function extractAndDeduplicateContentProductIds(data: EComProductLogListModel): string[] {
    const ids: string[] = [];

    data.list.forEach((log) => {
      if (log.prevValue && log.prevValue._content_product_id) {
        ids.push(log.prevValue._content_product_id);
      }
      if (log.currentValue && log.currentValue._content_product_id) {
        ids.push(log.currentValue._content_product_id);
      }
    });

    const uniqueIds = Array.from(new Set(ids));
    return uniqueIds;
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPid(e.target.value);
  };

  const handleInputPressEnter = () => {
    setQuery({
      ...query,
      pid: pid?.trim(),
      page: pid?.trim() ? 1 : query.page,
    });
  };

  const handleTableChange: TableProps<ProductLogModel>["onChange"] = ({ pageSize }, _, sorter, { action }) => {
    const { order } = sorter as SorterResult<ProductLogModel>;
    if (action === "sort") {
      setQuery({
        ...query,
        order: order as string,
        page: 1,
        limit: pageSize,
      });
    }
  };

  return (
    <>
      <Input
        allowClear
        value={pid}
        onChange={handleInputChange}
        onBlur={() => setPid((prevState) => prevState?.trim())}
        onPressEnter={handleInputPressEnter}
        prefix={<SearchOutlined />}
        placeholder="Search PID"
        style={{ width: 322, marginBottom: 24 }}
      />
      <Table
        loading={{
          spinning: isLoading,
        }}
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        columns={columnsToDisplay}
        dataSource={productInfo.list}
        onChange={handleTableChange}
        pagination={{
          showTotal: getTotalDisplay,
          current: query.page,
          pageSize: query.limit,
          total: productInfo?.total,
          defaultCurrent: 1,
          onChange: (page, pageSize) => {
            setQuery({
              ...query,
              page,
              limit: pageSize,
              order: query.order,
            });
          },
        }}
      />
    </>
  );
}
