import React, { useState } from "react";
import moment from "moment";
import { Form, message, ConfigProvider, InputNumber } from "antd";
import { ISubscriptionModalProps } from "./types";
import { EComModal } from "../../../../components/EComModal";
import { validateMessages } from "../../../../constants/common";
import modalStyles from "../../../../components/EComModal/index.module.scss";
import { preventNonNumericalInput } from "../../../../utils/preventNonNumericalInput";
import styles from "../Subscription.module.scss";
import ModalContent from "../../../../components/EComModal/ModalContent";
import ModalListItem from "../../../../components/EComModal/ModalListItem";
import { extendBillingDate } from "../../../../services/subscription";

export default function ExtendBillingDateModal({
  subscription,
  visible,
  closeModal,
  onFinish,
}: ISubscriptionModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [inputDays, setInputDays] = useState(0);

  async function onSubmit() {
    setIsLoading(true);
    try {
      const extendDays = await form.validateFields();
      await extendBillingDate(subscription.subscriptionId, extendDays.extendDays, subscription.source);
      message.success("Next billing date extended successfully");
      if (onFinish) {
        onFinish();
      }
      closeModal();
    } finally {
      setIsLoading(false);
    }
  }

  function validExtendDays() {
    const { extendDays } = form.getFieldsValue();
    const value = parseInt(extendDays);
    if (isNaN(value) || !value) {
      return Promise.reject("'Extend days' is required");
    } else if (Number(value) > 28) {
      return Promise.reject("'Extend days' cannot be longer than 28 days");
    } else {
      return Promise.resolve();
    }
  }

  function getNextBillingDate() {
    const nextBillingDate = moment(subscription.nextBilling?.date);
    return nextBillingDate.add(inputDays, "days").format("YYYY-MM-DD");
  }

  return (
    <EComModal
      title="Extend billing date"
      visible={visible}
      okText="Confirm"
      cancelText="Cancel"
      onCancel={closeModal}
      onOk={onSubmit}
      confirmLoading={isLoading}
    >
      <p>Please verify the information below with the user. </p>
      <ConfigProvider form={{ validateMessages }}>
        <Form className={modalStyles.form} form={form}>
          <Form.Item
            label="Extend Days"
            name="extendDays"
            required
            className={styles.inputNumber}
            rules={[
              {
                required: true,
                validator: validExtendDays,
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              precision={0}
              step={1}
              onKeyPress={preventNonNumericalInput}
              min={1}
              max={28}
              onChange={(value) => setInputDays(value)}
            />
          </Form.Item>
        </Form>
      </ConfigProvider>
      <ModalContent>
        <ModalListItem label="Next billing date:" value={getNextBillingDate()} />
      </ModalContent>
    </EComModal>
  );
}
