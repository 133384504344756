import { Button, Col, Divider, Form, Input, Row } from "antd";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import Icon from "@ant-design/icons";
import { Minus, Plus } from "assets/icons";
import NamiSupportCheckbox from "components/NamiSupportCheckbox";
import { FormInstance } from "antd/es/form";
import { useSelector } from "react-redux";
import styles from "./ProductUpsellForm.module.scss";
import { RootState } from "../../../../app/store";

interface Props {
  form: FormInstance;
}

const DISABLE_CHECKBOX_TYPES = ["DVD", "MULTI_LEVEL", "SUBSCRIPTION", "UPGRADE", "UPSELL", "ALL_ACCESS"];

export function SectionNami({ form }: Props) {
  const [isSupportedByNamiChecked, setIsSupportedByNamiChecked] = useState(
    form.getFieldValue("isUpsellSupportedByNami") || false
  );
  const namiGroups = form.getFieldValue("namiGroups");
  const currentProductId = form.getFieldValue("id");
  const currentUpsells = useSelector((state: RootState) => state.products.entities)[currentProductId]?.productUpsells;
  const defaultProductGroups = currentUpsells?.map((upsell) => `${upsell.upsellInAppIsbn}`) || [];
  const productType = form.getFieldValue("productType");
  const disableCheckboxByType = DISABLE_CHECKBOX_TYPES.includes(productType);

  return (
    <>
      <Form.Item name={"isUpsellSupportedByNami"} noStyle>
        <NamiSupportCheckbox
          checked={isSupportedByNamiChecked}
          onCheckboxChange={(isChecked) => {
            form.setFieldsValue({ isUpsellSupportedByNami: isChecked });
            setIsSupportedByNamiChecked(isChecked);
            isEmpty(namiGroups) &&
              form.setFieldsValue({
                namiGroups: !isEmpty(defaultProductGroups) ? defaultProductGroups : [""],
              });
          }}
          disabled={disableCheckboxByType}
        />
      </Form.Item>
      {isSupportedByNamiChecked && (
        <Form.Item label="Nami Paywall Product Group:">
          {
            <Form.List name={"namiGroups"}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item key={index}>
                      <Row gutter={24} style={{ width: "1056px" }}>
                        <Col span={8}>
                          <Form.Item
                            {...field}
                            noStyle
                            rules={[
                              {
                                required: true,
                                pattern: new RegExp("^[0-9a-zA-Z_-]{1,100}$", "g"),
                                message: "Please enter an alphanumeric value with no special characters.",
                              },
                            ]}
                          >
                            <Input placeholder={`Please enter product group id`} />
                          </Form.Item>
                        </Col>
                        {fields.length > 1 && (
                          <Button
                            className={styles.minusBtn}
                            onClick={() => remove(field.name)}
                            type="link"
                            icon={<Icon component={Minus} />}
                          />
                        )}
                      </Row>
                    </Form.Item>
                  ))}
                  <Button
                    className={styles.addNewUpsellBtn}
                    onClick={() => add()}
                    type="link"
                    icon={<Icon component={Plus} />}
                  >
                    Add new product group
                  </Button>
                </>
              )}
            </Form.List>
          }
        </Form.Item>
      )}
      <Divider />
    </>
  );
}
