import {
  CancelReasonModal,
  SaveOfferContent,
  SaveOfferDetailModel,
  SaveOfferFormData,
  SaveOfferListModel,
  SaveOfferModel,
  SaveOfferType,
} from "../model/saveOffer";
import {
  CancelReasonResponseData,
  SaveOfferDetailResponse,
  SaveOfferListResponse,
  SaveOfferResponse,
} from "../dto/response/saveOffer";
import { SaveOfferCreateRequest, SaveOfferListRequest } from "../dto/request/saveOffer";
import { parseContent } from "../../utils/richTextEditor";
import { transformPromotionDetail } from "./promotionV2";
import { LocaleType, supportLocalTabs } from "../../utils/locale";
import { NO_REASON_SELECTED_CANCEL_REASON_CODE } from "../../constants/common";

export function transformSaveOfferList(response: SaveOfferListResponse): SaveOfferListModel {
  return {
    list: response.data.map(transformSaveOffer),
    total: response.count,
  };
}

export function transformSaveOffer(response: SaveOfferResponse): SaveOfferModel {
  return {
    id: response.id,
    cancelReason: {
      id: response.cancel_reason?.id ?? "",
      code: response.cancel_reason?.code ?? NO_REASON_SELECTED_CANCEL_REASON_CODE,
      description: response.cancel_reason?.description.en ?? "",
    },
    appliedStages: response.applied_stages,
    appliedBasePrices: response.applied_base_prices,
    type: response.type,
    updatedAt: response.updated_at,
  };
}

export function transformSaveOfferListRequest({
  cancelReason,
  type,
  appliedStages,
  appliedBasePriceIds,
  order,
  offset,
  limit,
}: SaveOfferListRequest) {
  return {
    limit,
    offset,
    order,
    cancel_reason: cancelReason,
    type,
    applied_stages: appliedStages,
    applied_base_price_ids: appliedBasePriceIds,
  };
}

export function transformSaveOfferFormDataRequest(
  formData: SaveOfferFormData,
  contents: SaveOfferContent[]
): SaveOfferCreateRequest {
  const request: SaveOfferCreateRequest = {
    applied_base_price_ids: formData.appliedBasePriceIds,
    cancel_reason_code: formData.cancelReasonCode,
    applied_stages: formData.appliedStages,
    title: contents.reduce((acc, cur) => {
      acc[cur.locale] = cur.title;
      return acc;
    }, {} as { [key in LocaleType]: string }),
    description: contents.reduce((acc, cur) => {
      acc[cur.locale] = JSON.stringify(cur.description);
      return acc;
    }, {} as { [key in LocaleType]: string }),
    type: formData.saveOfferType,
  };

  switch (formData.saveOfferType) {
    case SaveOfferType.PROMOTION:
      request.promotion_code = formData.basePricePromotionCode;
      break;
    case SaveOfferType.EXTEND_BILLING_DATE:
      request.extend_days = Number(formData.extendDays);
      break;
    case SaveOfferType.SWITCH_PLAN:
      request.switch_to_plan_pid = formData.switchToPlanPid;
      request.promotion_code = formData.planPromotionCode;
      request.effective_date = formData.effectiveDate;
      break;
    default:
      break;
  }
  return request;
}

export function transformSaveOfferDetail(dto: SaveOfferDetailResponse): SaveOfferDetailModel {
  const model: SaveOfferDetailModel = {
    ...transformSaveOffer(dto),
    contents: supportLocalTabs.map((tab) => {
      return {
        label: tab.label,
        key: tab.key,
        locale: tab.locale,
        title: dto.title?.[tab.locale] ?? "",
        description: parseContent(dto.description?.[tab.locale] ?? ""),
        required: tab.required,
      };
    }),
  };

  dto.effective_date && (model.effectiveDate = dto.effective_date);
  dto.extend_days && (model.extendDays = dto.extend_days);
  dto.promotion && (model.promotion = transformPromotionDetail(dto.promotion));
  dto.switch_to_plan &&
    (model.switchToPlan = {
      pid: dto.switch_to_plan.pid,
      title: dto.switch_to_plan.title,
      billingPlanId: dto.switch_to_plan.billing_plan.id,
      billingPlanName: dto.switch_to_plan.billing_plan.name,
    });

  return model;
}

export function transformCancelReasonList(response: CancelReasonResponseData[]): CancelReasonModal[] {
  return response.map((data) => ({
    key: data.id,
    id: data.id,
    code: data.code,
    description: data.description,
    isUsed: data.is_used,
  }));
}
