import _, { join } from "lodash";
import { Checkbox, Col, Form, Row, Select } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FormInstance } from "antd/es/form";
import { DropDown } from "assets/icons";
import { SEPARATOR } from "constants/common";
import { message } from "components";
import { RootState, useAppDispatch } from "app/store";
import { fetchUpgradePromotionRecurly } from "../AddNewProduct/addProductSlice";
import styles from "./ProductUpsellForm.module.scss";
import { useCombinedProductInfo, useUpgradeEComProducts } from "./hooks";

interface Props {
  form: FormInstance;
}

export function SectionOneShotUpgradeInfo({ form }: Props) {
  const upgradeEComProducts = useUpgradeEComProducts(form);
  const combinedProductInfo = useCombinedProductInfo();
  const { upgradeProducts, upgradePromotionRecurly } = useSelector((state: RootState) => state.addProduct);
  const [hasUpgradePromotion, setHasUpgradePromotion] = useState(
    !_.isEmpty(form.getFieldValue("upgradePromotionCode"))
  );
  const dispatch = useAppDispatch();

  return (
    <>
      <Row gutter={24} style={{ width: "1056px" }}>
        <Col span={24}>
          <h3>One-shot Upgrade info: (upgrade from locked premium features)</h3>
        </Col>
        <Col span={11}>
          <Form.Item label="Upgrade ISBN" name={"upgradeId"}>
            <Select
              allowClear
              showSearch
              listHeight={160}
              optionFilterProp={"label"}
              getPopupContainer={(trigger) => trigger.parentNode}
              suffixIcon={<DropDown />}
              placeholder={"Please choose ISBN"}
              options={upgradeProducts.map((upgradeProduct) => ({
                label: join([upgradeProduct.productCode, upgradeProduct.productName], SEPARATOR),
                value: upgradeProduct.productId,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} style={{ width: "1056px" }}>
        <Col span={11}>
          <Form.Item
            label="eCom Upgrade PID"
            name={"upgradeEcomPid"}
            rules={[
              {
                validator: (_, value) => {
                  if (!value && hasUpgradePromotion) {
                    return Promise.reject(new Error("Please select a PID first."));
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Select
              allowClear
              showSearch
              listHeight={160}
              optionFilterProp={"label"}
              getPopupContainer={(trigger) => trigger.parentNode}
              suffixIcon={<DropDown />}
              placeholder={"Please choose PID"}
              options={upgradeEComProducts.map((eComProduct) => ({
                label: join(
                  [
                    eComProduct.pid,
                    combinedProductInfo.find((product) => product.isbn === eComProduct.pid.split("_")[0])?.courseName,
                    eComProduct.planName,
                  ],
                  SEPARATOR
                ),
                value: eComProduct.pid,
              }))}
              className={styles.dropdown}
              onChange={(value) => {
                form.setFieldsValue({
                  upgradePromotionCode: null,
                });
                value && dispatch(fetchUpgradePromotionRecurly(value as string)).catch(message.error);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Checkbox
            checked={hasUpgradePromotion}
            onChange={() => {
              setHasUpgradePromotion(!hasUpgradePromotion);
              form.validateFields([["upgradeEcomPid"]]).then(() => {
                !hasUpgradePromotion &&
                  _.isEmpty(form.getFieldValue("upgradePromotionCode")) &&
                  dispatch(fetchUpgradePromotionRecurly(form.getFieldValue("upgradeEcomPid"))).catch(message.error);
              });
            }}
            className={styles.checkbox}
          >
            eCom Promotion Code
          </Checkbox>
        </Col>
        {hasUpgradePromotion && (
          <Col span={8}>
            <Form.Item
              label="eCom Promotion Code"
              name={"upgradePromotionCode"}
              rules={[
                {
                  validator: (_, value) => {
                    if (!value && hasUpgradePromotion) {
                      return Promise.reject(new Error("'eCom Promotion Code' is required."));
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                listHeight={160}
                optionFilterProp={"label"}
                getPopupContainer={(trigger) => trigger.parentNode}
                suffixIcon={<DropDown />}
                placeholder={"Please choose promotion code"}
                options={upgradePromotionRecurly.map((promotion) => ({
                  label: join([promotion.code, promotion.status], SEPARATOR),
                  value: promotion.code,
                }))}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
    </>
  );
}
