import { Button, message } from "antd";
import { useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import useAppAuth0 from "../../../hooks/useAppAuth0";
import { TransactionModel, TransactionSource, TransactionStatus } from "../../../types/model/transactions";
import RefundTransactionModal from "./components/RefundTransactionModal";
import { RootState, useAppDispatch } from "../../../app/store";
import { customerSelectors, fetchTransactions } from "../customerSlice";

export default function Actions({ transaction }: { transaction: TransactionModel }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { user } = useAppAuth0();
  const { customerId } = useParams<{ customerId: string }>();
  const customer = useSelector((state: RootState) => customerSelectors.selectById(state, customerId));
  const dispatch = useAppDispatch();

  const showRefund =
    user.canRefundTransaction &&
    transaction.status === TransactionStatus.COMPLETED &&
    ((Number(transaction.price.total) > 0 &&
      (transaction.source === TransactionSource.ECOM || transaction.source === TransactionSource.NAVIGA)) ||
      (Number(transaction.price.refundableAmount) > 0 && transaction.source === TransactionSource.RECURLY));

  return (
    <div>
      {showRefund ? (
        <Button
          type="link"
          style={{ padding: "0" }}
          size="small"
          onClick={() => {
            setIsModalVisible(true);
          }}
        >
          Refund
        </Button>
      ) : (
        "-"
      )}
      {isModalVisible && (
        <RefundTransactionModal
          transaction={transaction}
          visible={isModalVisible}
          onFinish={() => {
            dispatch(fetchTransactions({ auth0Id: customer?.auth0Id ?? "" }))
              .then(unwrapResult)
              .catch(message.error);
          }}
          closeModal={() => setIsModalVisible(false)}
        />
      )}
    </div>
  );
}
