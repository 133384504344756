import { Button, Space, Tooltip } from "antd";
import styles from "./index.module.scss";
import useAppAuth0 from "../../../../hooks/useAppAuth0";

interface ViewButtonProps {
  isDeleteDisabled: boolean;
  disabledDeleteTooltips: string;
  onDelete: () => void;
  onEdit: () => void;
}

export default function ViewButtons({ isDeleteDisabled, disabledDeleteTooltips, onDelete, onEdit }: ViewButtonProps) {
  const { user } = useAppAuth0();
  const renderDeleteButton = () => (
    <Button type="default" shape="round" onClick={onDelete} disabled={isDeleteDisabled}>
      Delete
    </Button>
  );

  return (
    <Space size={8} className={styles.buttons}>
      {user.canDeletePromotion &&
        (isDeleteDisabled ? (
          <Tooltip title={disabledDeleteTooltips}>{renderDeleteButton()}</Tooltip>
        ) : (
          renderDeleteButton()
        ))}
      {user.canUpdatePromotion && (
        <Button type="default" shape="round" onClick={onEdit}>
          Edit
        </Button>
      )}
    </Space>
  );
}
