import { Col, Tabs } from "antd";
import styles from "./index.module.scss";
import { EComRichTextInput } from "../../../../components/EComRichText";
import ItemField from "../components/ItemField";
import { SaveOfferContent } from "../../../../types/model/saveOffer";
import { EMPTY } from "../../../../constants/common";

const { TabPane } = Tabs;

interface Props {
  content: SaveOfferContent[];
}

export default function ViewSaveOfferDetails({ content }: Props) {
  return (
    <div className={styles.content}>
      <span className={styles.sectionTitle}>Save offer details</span>
      <Col className={styles.tabCardContainer} span={18}>
        <Tabs type="card">
          {content.map((item: SaveOfferContent) => (
            <TabPane tab={item.label} key={item.key}>
              <div className={`${styles.detailSection} ${styles.multiple}`}>
                <div className={`${styles.sectionContent} ${styles.full}`}>
                  <ItemField label="Save offer title" value={item.title || EMPTY} />
                </div>
                <div className={`${styles.sectionContent} ${styles.full}`}>
                  <div className={styles.itemContainer}>
                    <span className={styles.itemLabel}>Save offer description:</span>
                    <EComRichTextInput editable={false} value={item.description} />
                  </div>
                </div>
              </div>
            </TabPane>
          ))}
        </Tabs>
      </Col>
    </div>
  );
}
