import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { invert } from "lodash";
import * as productAPI from "services/product";
import * as eComProductAPI from "services/eComProduct";

import {
  BasicProduct,
  BasicProductInfo,
  BasicPromotionRecurly,
  FetchOptionParams,
  ProductCreateRequest,
  ProductSelections,
} from "../../../../types/model/itemsForAddProduct";
import ProductModel, { isProductTypeCapital, ProductType } from "../../../../types/model/product";
import { PRODUCT_TYPE_VIEW_MAPPING } from "../../../../constants/common";
import { EComProductListRequest } from "../../../../types/dto/request/eComProduct";
import { CourseType, EComProductModel, EComProductStatus } from "../../../../types/model/eComProduct";
import Page from "../../../../types/page";
import ProductListRequest from "../../../../types/dto/request/ProductListRequest";

export const fetchSelectionItems = createAsyncThunk<ProductSelections, FetchOptionParams>(
  "products/fetchSelectionItems",
  async (params: FetchOptionParams) => {
    return await productAPI.fetchSelectionItems(params);
  }
);

export const createProduct = createAsyncThunk("createProduct", async (product: ProductCreateRequest) => {
  const currentProductType = product.type;
  product.type = isProductTypeCapital(currentProductType)
    ? (invert(PRODUCT_TYPE_VIEW_MAPPING)[currentProductType] as ProductType)
    : currentProductType;
  return await productAPI.createProduct(product);
});

export const fetchEComSelectionItems = createAsyncThunk(
  "products/fetchEComSelectionItems",
  async (languageId: number) => {
    return await eComProductAPI.fetchProductList({
      languageId,
      status: EComProductStatus.Active,
      limit: 1000,
    } as EComProductListRequest);
  }
);

export const fetchEComAllAccessSelectionItems = createAsyncThunk(
  "products/fetchEComAllAccessSelectionItems",
  async (languageId: number) => {
    return await eComProductAPI.fetchProductList({
      languageId,
      status: EComProductStatus.Active,
      limit: 1000,
    } as EComProductListRequest);
  }
);

export const fetchProductsInfoByLanguageId = createAsyncThunk<Page<ProductModel>, ProductListRequest>(
  "products/fetchProductsInfoByISBN",
  async ({ languageId }) => {
    return await productAPI.fetchAll({ languageId, size: 1000 });
  }
);

export const fetchAllAccessInfoByLanguageId = createAsyncThunk<Page<ProductModel>, ProductListRequest>(
  "products/fetchAllAccessInfoByLanguageId",
  async ({ languageId }) => {
    return await productAPI.fetchAll({ languageId, size: 1000 });
  }
);

export const fetchUpsellPromotionRecurly = createAsyncThunk(
  "addProduct/fetchUpsellPromotionRecurly",
  async ({ index, pid }: { index: number; pid: string }) => {
    const promotionData = await eComProductAPI.fetchPromotionListByPidRecurly(pid);
    return { index, promotionData };
  }
);

export const fetchUpgradePromotionRecurly = createAsyncThunk(
  "addProduct/fetchUpgradePromotionRecurly",
  async (pid: string) => {
    return await eComProductAPI.fetchPromotionListByPidRecurly(pid);
  }
);

const initialState = {
  singleLevelProducts: [] as BasicProduct[],
  premiumProducts: [] as BasicProduct[],
  inAppUpsellProducts: [] as BasicProduct[],
  upgradeProducts: [] as BasicProduct[],
  inAppSubscriptionUpsellProducts: [] as BasicProduct[],
  eComStandardProducts: [] as EComProductModel[],
  eComPremiumProducts: [] as EComProductModel[],
  eComAllAccessProducts: [] as EComProductModel[],
  productInfo: [] as BasicProductInfo[],
  allAccessInfo: [] as BasicProductInfo[],
  indexAndUpsellPromotionMapRecurly: {} as Record<string, BasicPromotionRecurly[]>,
  upgradePromotionRecurly: [] as BasicPromotionRecurly[],
};

export const addProductSlice = createSlice({
  name: "addProduct",
  initialState,
  reducers: {
    cleanup: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSelectionItems.fulfilled, (state, { payload }) => {
      state.singleLevelProducts = payload.singleLevelProducts || [];
      state.premiumProducts = payload.premiumProducts || [];
      state.inAppUpsellProducts = payload.inAppUpsellProducts || [];
      state.upgradeProducts = payload.upgradeProducts || [];
      state.inAppSubscriptionUpsellProducts = payload.inAppSubscriptionUpsellProducts || [];
    });
    builder.addCase(fetchSelectionItems.rejected, (state, { payload }) => {
      state.singleLevelProducts = [];
      state.premiumProducts = [];
      state.inAppUpsellProducts = [];
      state.upgradeProducts = [];
      state.inAppSubscriptionUpsellProducts = [];
    });
    builder.addCase(createProduct.fulfilled, (state, { payload }) => {});
    builder.addCase(fetchEComSelectionItems.fulfilled, (state, { payload }) => {
      state.eComStandardProducts = payload.list.filter((item) => item.courseType === CourseType.STANDARD);
      state.eComPremiumProducts = payload.list.filter((item) => item.courseType === CourseType.PREMIUM);
    });
    builder.addCase(fetchEComAllAccessSelectionItems.fulfilled, (state, { payload }) => {
      state.eComAllAccessProducts = payload.list;
    });
    builder.addCase(fetchProductsInfoByLanguageId.fulfilled, (state, { payload }) => {
      state.productInfo = payload.content.map((item) => {
        return { isbn: item.isbn, courseName: item.productName };
      });
    });
    builder.addCase(fetchAllAccessInfoByLanguageId.fulfilled, (state, { payload }) => {
      state.allAccessInfo = payload.content.map((item) => {
        return { isbn: item.isbn, courseName: item.productName };
      });
    });
    builder.addCase(fetchUpsellPromotionRecurly.fulfilled, (state, { payload }) => {
      const { index, promotionData } = payload;
      state.indexAndUpsellPromotionMapRecurly[index] = promotionData;
    });
    builder.addCase(fetchUpgradePromotionRecurly.fulfilled, (state, { payload }) => {
      state.upgradePromotionRecurly = payload;
    });
  },
});

export default addProductSlice.reducer;

export const { cleanup } = addProductSlice.actions;
