import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Card, Input, Space, Spin, Tooltip } from "antd";
import { RootState, useAppDispatch } from "app/store";
import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory, useParams } from "react-router";
import { EMPTY } from "constants/common";
import { message, Modal } from "components";
import { boldAndItalic } from "utils";
import useAppAuth0 from "hooks/useAppAuth0";
import CustomerBasicInfo from "./CustomerBasicInfo";
import * as CustomerAPI from "../../../services/customer";
import { CustomerTab } from "../CustomerList/CustomerList";
import CustomerEntitlements from "../CustomerEntitlements";
import LearningProgress from "../LearningProgress";
import InAppPurchaseRecord from "../InAppPurchaseRecord";
import { customerSelectors, fetchCustomerDetail, fetchEntitlementList, removeCustomer } from "../customerSlice";

import Transactions from "../Transactions";
import PaymentMethod from "../PaymentMethod";
import { PaymentMethod as PaymentMethodModel } from "../../../types/model/paymentMethod";
import Subscriptions from "../Subscriptions";
import { CustomerInfoResponse, CustomerResponse } from "../../../types/dto/response/customer";
import GiftCardBalance from "../GiftCardBalance";
import { CustomerDetailsTab, CustomerSubscriberInfo } from "../../../types/model/customer";
import GiftComponent from "../Gift";
import { SharedAccount } from "../../../assets/icons";

interface Props {
  tab: CustomerTab;
}

function CustomerDetail({ tab }: Props) {
  const { user } = useAppAuth0();
  const dispatch = useAppDispatch();
  const { customerId } = useParams<{ customerId: string }>();
  const [paymentMethodModel, setPaymentMethodModel] = useState<PaymentMethodModel>({} as PaymentMethodModel);
  const [activeTabKey, setActiveTabKey] = useState<string>(CustomerDetailsTab.CustomerInfo);
  const [customerSubscriberInfo, setCustomerSubscriberInfo] = useState<CustomerSubscriberInfo>();

  const tabList = useMemo(
    () =>
      tab === "B2C"
        ? [
            {
              key: CustomerDetailsTab.CustomerInfo,
              tab: CustomerDetailsTab.CustomerInfo,
            },
            {
              key: CustomerDetailsTab.Transactions,
              tab: CustomerDetailsTab.Transactions,
            },
            {
              key: CustomerDetailsTab.Subscriptions,
              tab: CustomerDetailsTab.Subscriptions,
            },
            {
              key: CustomerDetailsTab.LearningProgress,
              tab: CustomerDetailsTab.LearningProgress,
            },
            {
              key: CustomerDetailsTab.InAppPurchaseRecord,
              tab: CustomerDetailsTab.InAppPurchaseRecord,
            },
            {
              key: CustomerDetailsTab.Gift,
              tab: CustomerDetailsTab.Gift,
            },
          ]
        : [
            {
              key: CustomerDetailsTab.CustomerInfo,
              tab: CustomerDetailsTab.CustomerInfo,
            },
            {
              key: CustomerDetailsTab.LearningProgress,
              tab: CustomerDetailsTab.LearningProgress,
            },
          ],
    [tab]
  );

  const isLoading = useSelector((state: RootState) => state.loading);
  const customer = useSelector((state: RootState) => customerSelectors.selectById(state, customerId));
  const history = useHistory();

  useEffect(() => {
    customerId &&
      dispatch(fetchEntitlementList(Number(customerId)))
        .then(unwrapResult)
        .catch(message.error);
  }, [dispatch, customerId]);

  useEffect(() => {
    getUserDetail();
  }, [dispatch, customerId]);

  useEffect(() => {
    getPaymentMethod();
    getCustomerSubscriberInfo();
  }, [customer?.auth0Id]);

  function getUserDetail() {
    customerId &&
      dispatch(fetchCustomerDetail(Number(customerId)))
        .then(unwrapResult)
        .catch(message.error);
  }

  function getCustomerSubscriberInfo() {
    customer?.auth0Id &&
      customer.email &&
      CustomerAPI.fetchUserSubscriberInfo(customer.email).then((res) => {
        setCustomerSubscriberInfo(res);
      });
  }

  function getPaymentMethod() {
    customer?.auth0Id &&
      CustomerAPI.fetchUserDefaultPaymentMethod(customer.auth0Id).then((res) => {
        res && setPaymentMethodModel(res);
      });
  }

  function onDelete() {
    const modal = Modal.confirm({
      title: "Confirm",
      content: (
        <Space direction="vertical" size={12}>
          {`Please be aware that we will delete all this customer's information, including Auth0 account, entitlement, learning progress, vocabulary. This can not be undone.`}
          {boldAndItalic`To confirm deletion, type ${customer?.email ?? ""}.`}
          <Input
            onChange={(e) =>
              modal.update((prevConfig) => ({
                ...prevConfig,
                okButtonProps: {
                  ...prevConfig.okButtonProps,
                  disabled: e.target.value !== customer?.email,
                },
              }))
            }
          />
        </Space>
      ),
      okText: "Delete",
      okButtonProps: {
        disabled: true,
        shape: "round",
        style: { minWidth: "92px" },
      },
      onOk: () =>
        dispatch(removeCustomer(customer?.customerId!))
          .then(unwrapResult)
          .then(() => history.push("/customers"))
          .catch(message.error),
    });
  }

  const onTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  return (
    <Spin spinning={isLoading} size={"large"}>
      <Card
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            {customer?.email || EMPTY}
            {customer?.sharedCustomers && customer?.sharedCustomers.length > 0 && (
              <Tooltip
                title={
                  <div>
                    <div>This user has more than one account. Some other accounts detected</div>
                    <div>
                      {customer.sharedCustomers.map((sharedCustomer) => (
                        <div key={sharedCustomer.email}>
                          <a
                            href={`/customers/b2c/${sharedCustomer.id}`}
                            style={{ color: "white", textDecoration: "underline" }}
                          >
                            {sharedCustomer.email || "Anonymous account"}
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                }
                placement={"bottomRight"}
                overlayStyle={{
                  maxWidth: "550px",
                  minWidth: "450px",
                  color: "#333",
                  whiteSpace: "normal",
                }}
              >
                <SharedAccount height={30} width={30} style={{ marginLeft: 10 }} />
              </Tooltip>
            )}
          </div>
        }
        extra={
          user.canDeleteCustomer && (
            <Button onClick={onDelete} shape="round" type="default">
              Delete
            </Button>
          )
        }
        tabList={tabList}
        activeTabKey={activeTabKey}
        onTabChange={onTabChange}
        className="page-container"
      >
        {activeTabKey === CustomerDetailsTab.CustomerInfo && (
          <div style={{ display: "flex", flexDirection: "column", gap: 24 }}>
            <CustomerBasicInfo
              tab={tab}
              customer={customer}
              customerSubscriberInfo={customerSubscriberInfo}
              onFinishEditing={getUserDetail}
            />
            {tab === "B2C" && (
              <PaymentMethod
                auth0Id={customer?.auth0Id || EMPTY}
                paymentMethod={paymentMethodModel}
                onFinishEditing={getPaymentMethod}
              />
            )}
            {tab === "B2C" && <GiftCardBalance auth0Id={customer?.auth0Id} />}
            <CustomerEntitlements tab={tab} auth0Id={customer?.auth0Id} />
          </div>
        )}
        {activeTabKey === CustomerDetailsTab.Transactions && <Transactions customer={customer} />}
        {activeTabKey === CustomerDetailsTab.Subscriptions && (
          <Subscriptions customer={customer || ({} as CustomerResponse & CustomerInfoResponse)} />
        )}
        {activeTabKey === CustomerDetailsTab.LearningProgress && <LearningProgress />}
        {activeTabKey === CustomerDetailsTab.InAppPurchaseRecord && <InAppPurchaseRecord />}
        {activeTabKey === CustomerDetailsTab.Gift && <GiftComponent auth0Id={customer?.auth0Id || EMPTY} />}
      </Card>
    </Spin>
  );
}

export default CustomerDetail;
