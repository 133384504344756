import React, { ChangeEvent, HTMLAttributes, SyntheticEvent, useEffect, useState } from "react";
import { Card, Input, Table, TableProps } from "antd";
import { ColumnsType, ColumnType } from "antd/es/table";
import { SorterResult } from "antd/es/table/interface";
import AddButton from "components/AddButton";
import moment from "moment";
import { ResizeCallbackData } from "react-resizable";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import { useHistory, useRouteMatch } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { getTotalDisplay } from "../../../../utils/getTotalDisplay";
import ResizableTitle from "../../../../components/ResizableTitle";
import { DATETIME_FORMAT, EMPTY } from "../../../../constants/common";
import * as upsellDowngradeAPI from "../../../../services/upsellDowngrade";
import { getOffset } from "../../../../utils/getPagination";
import { updateSortOrder } from "../../../../types/transformers/updateSortOrder";
import { UpsellDowngradeListModel, UpsellDowngradeModel } from "../../../../types/model/upsellDowngrade";
import styles from "./index.module.scss";
import useAppAuth0 from "../../../../hooks/useAppAuth0";

const columns: ColumnsType<UpsellDowngradeModel> = [
  {
    title: "Original base price",
    key: "originalBasePriceName",
    dataIndex: "originalBasePriceName",
    ellipsis: true,
    render: (value: string) => value,
    width: 221,
  },
  {
    title: "Primary upsell base price",
    key: "primaryUpsellBasePriceName",
    dataIndex: "primaryUpsellBasePriceName",
    ellipsis: true,
    render: (value: string) => value || EMPTY,
    width: 221,
  },
  {
    title: "Secondary upsell base price",
    key: "secondaryUpsellBasePriceName",
    dataIndex: "secondaryUpsellBasePriceName",
    ellipsis: true,
    render: (value: string) => value || EMPTY,
    width: 221,
  },
  {
    title: "Downgrade base price",
    key: "downgradeUpsellBasePriceName",
    dataIndex: "downgradeUpsellBasePriceName",
    ellipsis: true,
    render: (value: string) => value || EMPTY,
    width: 221,
  },
  {
    title: "Modified at",
    key: "updatedAt",
    dataIndex: "updatedAt",
    width: 177,
    ellipsis: true,
    defaultSortOrder: "descend",
    sortDirections: ["descend", "ascend", "descend"],
    sorter: true,
    render: (value: string) => moment(value).format(DATETIME_FORMAT),
  },
];

function UpsellDowngradeList() {
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 10),
    order: withDefault(StringParam, "-updated_at"),
    name: withDefault(StringParam, undefined),
  });
  const { user } = useAppAuth0();
  const [tableColumns, setTableColumns] = useState<ColumnsType<UpsellDowngradeModel>>(columns);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<UpsellDowngradeListModel>();
  const history = useHistory();
  const { path } = useRouteMatch();
  const [originalBasePriceName, setOriginalBasePriceName] = useState(query.name);

  const handleResize = (index: number) => (_: SyntheticEvent, { size }: ResizeCallbackData) => {
    const nextColumns = [...tableColumns];
    nextColumns[index] = {
      ...nextColumns[index],
      width: size.width,
    };
    setTableColumns(nextColumns);
  };

  const columnsToDisplay = tableColumns.map((col, index) => ({
    ...col,
    onHeaderCell: (column: ColumnType<UpsellDowngradeModel>) =>
      ({
        width: column.width,
        onResize: handleResize(index),
      } as HTMLAttributes<HTMLElement>),
  }));

  const handleOnRowClick: TableProps<UpsellDowngradeModel>["onRow"] = (record) => ({
    onClick: () =>
      history.push({
        pathname: `${path}/${record.id}`,
      }),
  });

  const handleTableChange: TableProps<UpsellDowngradeModel>["onChange"] = ({ pageSize }, _, sorter, { action }) => {
    const { order } = sorter as SorterResult<UpsellDowngradeModel>;
    if (action === "sort") {
      setQuery({
        ...query,
        order: updateSortOrder[order as string],
        page: 1,
        limit: pageSize,
      });
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOriginalBasePriceName(e.target.value);
  };

  const handleInputPressEnter = () => {
    setQuery({ ...query, name: originalBasePriceName?.trim(), page: 1 });
  };

  useEffect(() => {
    let ignore = false;

    setLoading(true);
    upsellDowngradeAPI
      .fetchUpsellDowngradeList({
        order: query.order,
        limit: query.limit,
        offset: getOffset(query.page, query.limit),
        original_name: originalBasePriceName,
      })
      .then((data) => {
        if (!ignore) {
          setData(data);
        }
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      ignore = true;
    };
  }, [query]);

  return (
    <Card
      title="Upsell & Downgrade list"
      extra={user.canCreateUpsellDowngrade && <AddButton text={"+ Add new upsell & downgrade"} />}
      className="page-container"
    >
      <Input
        allowClear
        value={originalBasePriceName}
        onChange={handleInputChange}
        onBlur={() => setOriginalBasePriceName((prevState) => prevState?.trim())}
        onPressEnter={handleInputPressEnter}
        className={styles.basePriceNameInput}
        prefix={<SearchOutlined />}
        placeholder="Search original base price"
      />
      <Table<UpsellDowngradeModel>
        loading={{
          spinning: isLoading,
        }}
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        rowKey={(record) => record.id}
        columns={columnsToDisplay}
        dataSource={data?.list}
        onChange={handleTableChange}
        pagination={{
          showTotal: getTotalDisplay,
          current: query.page,
          pageSize: query.limit,
          total: data?.total,
          showSizeChanger: true,
          onChange: (page, pageSize) =>
            setQuery({
              ...query,
              page,
              limit: pageSize,
            }),
        }}
        onRow={handleOnRowClick}
      />
    </Card>
  );
}

export default UpsellDowngradeList;
