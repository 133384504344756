import React from "react";
import { Col, Divider, Form, Row, Space } from "antd";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import styles from "./index.module.scss";
import { DisplayInput } from "../../../../components";
import CustomerDot from "../../../../components/CustomDot";
import { DescriptionAndDisclosureContentView } from "../components/DescriptionAndDisclosure";
import { PromotionWithBuyOneGiftOneDetailsModal } from "../../../../types/model/promotionV2";
import { DATETIME_FORMAT_WITH_MINUTE } from "../../../../constants/common";
import { getDisplayPromotionDiscountType, getDisplayPromotionStatus } from "../util";
import { PromotionSource } from "../components/PromotionForm";

interface PromotionDetailsViewForEComSourceProps {
  promotionDetails?: PromotionWithBuyOneGiftOneDetailsModal;
}

export default function PromotionDetailsViewForEComSource({
  promotionDetails,
}: PromotionDetailsViewForEComSourceProps) {
  const [form] = Form.useForm();

  if (promotionDetails) {
    form.setFieldsValue({
      source: PromotionSource.BUY_ONE_GIFT_ONE,
      code: promotionDetails.code,
      name: promotionDetails.name,
      timeRange: `${moment(promotionDetails.startedAt).local().format(DATETIME_FORMAT_WITH_MINUTE)} to ${moment(
        promotionDetails.endedAt
      )
        .local()
        .format(DATETIME_FORMAT_WITH_MINUTE)}`,
      status: getDisplayPromotionStatus(promotionDetails.status),
      basePlan: `${promotionDetails.buyPlan.pid} ${promotionDetails.buyPlan.title} / ${promotionDetails.buyPlan.billingPlanName}`,
      giftPlan: promotionDetails.giftPlan.title,
      discountType: getDisplayPromotionDiscountType(promotionDetails.discountType),
      codeTextEn: promotionDetails.codeText?.en,
      codeTextEs: promotionDetails.codeText?.es,
    });

    if (promotionDetails.descriptionDisclosures && !isEmpty(promotionDetails.descriptionDisclosures)) {
      const { description = [], disclosure = [] } = promotionDetails.descriptionDisclosures[0];
      description?.forEach((item) => {
        form.setFieldsValue({
          [`${item.locale}DescriptionContent`]: item.content,
        });
      });
      disclosure?.forEach((item) => {
        form.setFieldsValue({
          [`${item.locale}DisclosureContent`]: item.content,
        });
      });
    }
  }

  return (
    <Form className={styles.promotionDetailContainer} form={form} layout="vertical">
      <Space direction="vertical" size={24} className={styles.formSections}>
        <header className={styles.subtitle}>Promotion details</header>
        <Row>
          <Col span={24}>
            <Form.Item name="source" label="Promotion source">
              <DisplayInput />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item name="code" label="Promotion code">
              <DisplayInput />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="name" label="Promotion name">
              <DisplayInput />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item name="timeRange" label="Time Range">
              <DisplayInput />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="discountType" label="Promotion type">
              <DisplayInput />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="status" label="Status">
              <CustomerDot color={getDisplayPromotionStatus(promotionDetails?.status)}>
                <DisplayInput value={form.getFieldValue("status")} />
              </CustomerDot>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item name="codeTextEn" label="Promotion code text(English)">
              <DisplayInput />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="codeTextEs" label="Promotion code text(Spanish)">
              <DisplayInput />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row>
          <Col span={18}>
            <Form.Item name="basePlan" label="Base plan">
              <DisplayInput />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item name="giftPlan" label="Gift plan">
              <DisplayInput />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <header className={styles.subtitle}>Description & Disclosure</header>
        <DescriptionAndDisclosureContentView />
      </Space>
    </Form>
  );
}
