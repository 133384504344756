import { Button, Checkbox, Col, Divider, Form, Radio, Row, Select } from "antd";
import React, { useEffect } from "react";
import _, { join } from "lodash";
import Icon from "@ant-design/icons";
import { DropDown, Minus, Plus } from "assets/icons";
import { FormInstance } from "antd/es/form";
import { SEPARATOR } from "constants/common";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "app/store";
import { message } from "components";
import { upsellOptions } from "../../../entitlements/RList/utils";
import { fetchUpsellPromotionRecurly } from "../AddNewProduct/addProductSlice";
import styles from "./ProductUpsellForm.module.scss";
import { useCombinedProductInfo, useUpgradeEComProducts } from "./hooks";

interface Props {
  form: FormInstance;
  onRemove: (fieldsLength: number) => void;
  onAdd: () => void;
}

export function SectionUpsellInfo({ form, onRemove, onAdd }: Props) {
  const upgradeEComProducts = useUpgradeEComProducts(form);
  const combinedProductInfo = useCombinedProductInfo();
  const {
    inAppUpsellProducts,
    upgradeProducts,
    inAppSubscriptionUpsellProducts,
    eComStandardProducts,
    eComPremiumProducts,
    eComAllAccessProducts,
    indexAndUpsellPromotionMapRecurly,
  } = useSelector((state: RootState) => state.addProduct);
  const dispatch = useAppDispatch();
  const productUpsells = form.getFieldValue("productUpsells");
  const isPremium = form.getFieldValue("isPremium");
  const eComStandardSubscriptionProducts = eComStandardProducts.filter(
    (product) => product.contentProductType === "Subscription"
  );
  const eComPremiumSubscriptionProducts = eComPremiumProducts.filter(
    (product) => product.contentProductType === "Subscription"
  );
  const eComStandardUpsellProducts = eComStandardProducts.filter(
    (product) => product.contentProductType === "Single level"
  );
  const eComPremiumUpsellProducts = eComPremiumProducts.filter(
    (product) => product.contentProductType === "Single level"
  );
  const subscriptionEComProducts = isPremium
    ? [...eComAllAccessProducts, ...eComPremiumSubscriptionProducts]
    : [...eComAllAccessProducts, ...eComStandardSubscriptionProducts];
  const upsellEComProducts = isPremium ? [...eComPremiumUpsellProducts] : [...eComStandardUpsellProducts];

  useEffect(() => {
    productUpsells?.forEach((upsell: { upsellPromotionCode?: string; upsellEcomPid?: string }, index: number) => {
      !_.isEmpty(upsell.upsellPromotionCode) &&
        upsell.upsellEcomPid &&
        dispatch(fetchUpsellPromotionRecurly({ index, pid: upsell.upsellEcomPid })).catch(message.error);
    });
  }, []);

  const handleUpsellTypeChange = (index: number) => {
    const { productUpsells } = form.getFieldsValue();
    productUpsells[index] = {
      ...productUpsells[index],
      upsellInAppId: null,
      upsellEcomPid: null,
      hasEComPromotionCode: false,
    };
    form.setFieldsValue({
      productUpsells,
    });
  };

  const handleClickPromotion = (index: number, hasEComPromotionCode: boolean) => {
    const { productUpsells } = form.getFieldsValue();
    productUpsells[index] = {
      ...productUpsells[index],
      hasEComPromotionCode,
      upsellPromotionCode: null,
    };
    form.setFieldsValue({
      productUpsells,
    });
  };

  const handleChangeEComUpsell = (index: number, value?: string) => {
    const { productUpsells } = form.getFieldsValue();
    productUpsells[index] = {
      ...productUpsells[index],
      upsellPromotionCode: null,
    };
    form.setFieldsValue({
      productUpsells,
    });
    value && dispatch(fetchUpsellPromotionRecurly({ index, pid: value })).catch(message.error);
  };

  return (
    <>
      <Col span={24}>
        <h3>Upsell info: (upsell from the end of a level)</h3>
      </Col>
      <Form.List name="productUpsells">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <React.Fragment key={index}>
                <Row gutter={24} style={{ width: "1056px", alignItems: "baseline" }}>
                  <Col span={3}>
                    <h4>Upsell Card {index + 1}</h4>
                  </Col>
                  <Col span={1}>
                    <Button
                      className={styles.minusBtn}
                      onClick={() => {
                        remove(field.name);
                        onRemove(fields.length);
                      }}
                      type="link"
                      icon={<Icon component={Minus} />}
                    />
                  </Col>
                </Row>
                <Row gutter={24} style={{ width: "1056px" }}>
                  <Col span={12}>
                    <Form.Item
                      {...field}
                      label={"Upsell Type"}
                      rules={[
                        {
                          required: true,
                          message: "'Upsell Type' is required",
                        },
                      ]}
                      name={[field.name, "upsellType"]}
                      initialValue={productUpsells[index]?.upsellType || "SUBSCRIPTION"}
                      fieldKey={[field.fieldKey, "upsellType"]}
                      className={styles.smallFormItem}
                    >
                      <Radio.Group options={upsellOptions} onChange={() => handleUpsellTypeChange(index)} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24} style={{ width: "1056px" }}>
                  <Form.Item noStyle shouldUpdate={true}>
                    {(formInstance) => {
                      const currentProductUpsells = formInstance.getFieldValue("productUpsells") || productUpsells;
                      const { upsellType } = currentProductUpsells[index];
                      let options = inAppSubscriptionUpsellProducts;
                      if (upsellType === "UPSELL") {
                        options = inAppUpsellProducts;
                      } else if (upsellType === "UPGRADE") {
                        options = upgradeProducts;
                      }

                      return (
                        <Col span={11}>
                          <Form.Item
                            {...field}
                            label="In App Upsell ISBN"
                            rules={[
                              {
                                required: true,
                                message: "'In App Upsell ISBN' is required",
                              },
                            ]}
                            name={[field.name, "upsellInAppId"]}
                          >
                            <Select
                              allowClear
                              showSearch
                              listHeight={160}
                              optionFilterProp={"label"}
                              getPopupContainer={(trigger) => trigger.parentNode}
                              suffixIcon={<DropDown />}
                              placeholder={"Please choose ISBN"}
                              options={options.map((productOption) => ({
                                label: join([productOption.productCode, productOption.productName], SEPARATOR),
                                value: productOption.productId,
                              }))}
                            />
                          </Form.Item>
                        </Col>
                      );
                    }}
                  </Form.Item>
                </Row>
                <Row gutter={24} style={{ width: "1056px" }}>
                  <Form.Item noStyle shouldUpdate={true}>
                    {(formInstance) => {
                      const currentProductUpsells = formInstance.getFieldValue("productUpsells") || productUpsells;
                      const { upsellType } = currentProductUpsells[index];
                      let eComOptions = subscriptionEComProducts;
                      if (upsellType === "UPSELL") {
                        eComOptions = upsellEComProducts;
                      } else if (upsellType === "UPGRADE") {
                        eComOptions = upgradeEComProducts;
                      }
                      const promotionOptions = indexAndUpsellPromotionMapRecurly[index];

                      return (
                        <>
                          <Col span={11}>
                            <Form.Item
                              {...field}
                              label="eCom Upsell PID"
                              name={[field.name, "upsellEcomPid"]}
                              rules={[
                                {
                                  validator: (_, value) => {
                                    const hasEComPromotionCode = form.getFieldValue("productUpsells")[index]
                                      ?.hasEComPromotionCode;
                                    if (!value && hasEComPromotionCode) {
                                      return Promise.reject(new Error("Please select a PID first."));
                                    } else {
                                      return Promise.resolve();
                                    }
                                  },
                                },
                              ]}
                            >
                              <Select
                                allowClear
                                showSearch
                                listHeight={160}
                                optionFilterProp={"label"}
                                getPopupContainer={(trigger) => trigger.parentNode}
                                suffixIcon={<DropDown />}
                                placeholder={"Please choose PID"}
                                options={eComOptions.map((eComProduct) => ({
                                  label: join(
                                    [
                                      eComProduct.pid,
                                      combinedProductInfo.find(
                                        (product) => product.isbn === eComProduct.pid.split("_")[0]
                                      )?.courseName,
                                      eComProduct.planName,
                                    ],
                                    SEPARATOR
                                  ),
                                  value: eComProduct.pid,
                                }))}
                                className={styles.dropdown}
                                onChange={(v) => {
                                  handleChangeEComUpsell(index, v as string);
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={5}>
                            <Form.Item
                              {...field}
                              name={[field.name, "hasEComPromotionCode"]}
                              initialValue={!_.isEmpty(productUpsells[index]?.upsellPromotionCode)}
                            >
                              <Checkbox
                                checked={form.getFieldValue("productUpsells")[index]?.hasEComPromotionCode}
                                onChange={() => {
                                  const currentProductUpsells = form.getFieldValue("productUpsells")[index];
                                  handleClickPromotion(index, !currentProductUpsells?.hasEComPromotionCode);
                                  form.validateFields([["productUpsells", index, "upsellEcomPid"]]).then(() => {
                                    !currentProductUpsells?.hasEComPromotionCode &&
                                      _.isEmpty(currentProductUpsells.upsellPromotionCode) &&
                                      dispatch(
                                        fetchUpsellPromotionRecurly({ index, pid: currentProductUpsells.upsellEcomPid })
                                      ).catch(message.error);
                                  });
                                }}
                                className={styles.checkbox}
                              >
                                eCom Promotion Code
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          {form.getFieldValue("productUpsells")[index]?.hasEComPromotionCode && (
                            <Col span={8}>
                              <Form.Item
                                {...field}
                                label="eCom Promotion Code"
                                name={[field.name, "upsellPromotionCode"]}
                                rules={[
                                  {
                                    validator: (_, value) => {
                                      const hasEComPromotionCode = form.getFieldValue("productUpsells")[index]
                                        ?.hasEComPromotionCode;
                                      if (!value && hasEComPromotionCode) {
                                        return Promise.reject(new Error("'eCom Promotion Code' is required."));
                                      } else {
                                        return Promise.resolve();
                                      }
                                    },
                                  },
                                ]}
                              >
                                <Select
                                  allowClear
                                  showSearch
                                  listHeight={160}
                                  optionFilterProp={"label"}
                                  getPopupContainer={(trigger) => trigger.parentNode}
                                  suffixIcon={<DropDown />}
                                  placeholder={"Please choose promotion code"}
                                  options={promotionOptions?.map((promotion) => ({
                                    label: join([promotion.code, promotion.status], SEPARATOR),
                                    value: promotion.code,
                                  }))}
                                />
                              </Form.Item>
                            </Col>
                          )}
                        </>
                      );
                    }}
                  </Form.Item>
                </Row>
                {fields.length - 1 > index && <Divider />}
              </React.Fragment>
            ))}
            <Button
              className={styles.addNewUpsellBtn}
              onClick={() => {
                add();
                onAdd();
              }}
              type="link"
              icon={<Icon component={Plus} />}
            >
              Add new upsell
            </Button>
            <Divider />
          </>
        )}
      </Form.List>
    </>
  );
}
