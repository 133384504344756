import React, { useMemo } from "react";
import { Col, Divider, Form, Row, Space } from "antd";
import moment from "moment";
import { PromotionCodeUsageType, PromotionWithRecurlyDetailsModal } from "../../../../types/model/promotionV2";
import { PromotionSource } from "../components/PromotionForm";
import { DATETIME_FORMAT_WITH_MINUTE, EMPTY } from "../../../../constants/common";
import {
  getBulkPromotionDisplayCode,
  getDisplayDiscount,
  getDisplayPromotionCodeUsage,
  getDisplayPromotionDiscountType,
  getDisplayPromotionStatus,
  getPromotionCodeLabel,
} from "../util";
import styles from "./index.module.scss";
import { DisplayInput } from "../../../../components";
import CustomerDot from "../../../../components/CustomDot";
import { DescriptionAndDisclosure } from "../components/DescriptionAndDisclosure";
import PromotionCodeList from "./PromotionCodeList";

interface PromotionDetailsViewForRecurlySourceProps {
  promotionDetails: PromotionWithRecurlyDetailsModal;
}

export default function PromotionDetailsViewForRecurlySource({
  promotionDetails,
}: PromotionDetailsViewForRecurlySourceProps) {
  const [form] = Form.useForm();
  const isSingleUsePromotion = useMemo(
    () => promotionDetails?.codeUsage && promotionDetails.codeUsage === PromotionCodeUsageType.BULK,
    [promotionDetails?.codeUsage]
  );

  if (promotionDetails) {
    form.setFieldsValue({
      source: PromotionSource.RECURLY,
      recurlyUrl: `${process.env.REACT_APP_RECURLY_COUPON_URL}/${promotionDetails.recurlyCouponId}`,
      codeUsage: getDisplayPromotionCodeUsage(promotionDetails.codeUsage),
      quantity: promotionDetails.quantity,
      code: getBulkPromotionDisplayCode(promotionDetails.code, promotionDetails.codeUsage),
      name: promotionDetails.name,
      timeRange: `${moment(promotionDetails.startedAt).local().format(DATETIME_FORMAT_WITH_MINUTE)} to ${
        promotionDetails.endedAt ? moment(promotionDetails.endedAt).local().format(DATETIME_FORMAT_WITH_MINUTE) : EMPTY
      }`,
      discount: getDisplayDiscount(
        promotionDetails.discountType,
        promotionDetails.percent,
        promotionDetails.currencies
      ),
      status: getDisplayPromotionStatus(promotionDetails.status),
      discountType: getDisplayPromotionDiscountType(promotionDetails.discountType),
      codeTextEn: promotionDetails.codeText?.en,
      codeTextEs: promotionDetails.codeText?.es,
      basePrices: promotionDetails.appliedBasePrices?.map((basePrice) => basePrice.name).join(", "),
      countries: promotionDetails.appliedCountries?.map((country) => country.name).join(", "),
    });
  }

  return (
    <>
      <Form className={styles.promotionDetailContainer} form={form} layout="vertical">
        <Space direction="vertical" size={24} className={styles.formSections}>
          <header className={styles.subtitle}>Promotion details</header>
          <Row>
            <Col span={12}>
              <Form.Item name="source" label="Promotion source">
                <DisplayInput />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="recurlyUrl" label="Recurly promotion details">
                <a
                  href={form.getFieldValue("recurlyUrl")}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.link}
                >
                  <DisplayInput value={form.getFieldValue("recurlyUrl")} />
                </a>
              </Form.Item>
            </Col>
          </Row>

          {isSingleUsePromotion && (
            <Row>
              <Col span={12}>
                <Form.Item name="codeUsage" label="Promotion code usage">
                  <DisplayInput />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="quantity" label="Quantity">
                  <DisplayInput />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row>
            <Col span={12}>
              <Form.Item name="code" label={getPromotionCodeLabel(promotionDetails?.codeUsage)}>
                <DisplayInput />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="name" label="Promotion name">
                <DisplayInput />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item name="timeRange" label="Time Range">
                <DisplayInput />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="discountType" label="Promotion type">
                <DisplayInput />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item name="discount" label="Discount">
                <DisplayInput />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="status" label="Status">
                <CustomerDot color={getDisplayPromotionStatus(promotionDetails?.status)}>
                  <DisplayInput value={form.getFieldValue("status")} />
                </CustomerDot>
              </Form.Item>
            </Col>
          </Row>

          {isSingleUsePromotion && (
            <Row>
              <Col span={12}>
                <Form.Item label="Promotion code list">
                  <PromotionCodeList
                    code={promotionDetails.code}
                    count={promotionDetails.quantity ?? 0}
                    status={promotionDetails.status}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row>
            <Col span={12}>
              <Form.Item name="codeTextEn" label="Promotion code text(English)">
                <DisplayInput />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="codeTextEs" label="Promotion code text(Spanish)">
                <DisplayInput />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Row gutter={32}>
            <Col span={24}>
              <Form.Item name="basePrices" label="Applied base price">
                <DisplayInput />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={6}>
              <Form.Item name="countries" label="Applied countries/regions">
                <DisplayInput />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
        </Space>
      </Form>
      {promotionDetails && (
        <DescriptionAndDisclosure
          editable={false}
          descriptionDisclosures={promotionDetails?.descriptionDisclosures || []}
        />
      )}
    </>
  );
}
