import {
  PromotionBulkListModel,
  PromotionWithBuyOneGiftOneDetailsModal,
  PromotionWithBuyOneGiftOneFormData,
  PromotionWithRecurlyDetailsModal,
  PromotionWithRecurlyFormData,
  RecurlyCouponModel,
} from "../types/model/promotionV2";
import { eComInstance } from "../utils/instance";
import {
  PromotionBulkListResponse,
  PromotionListResponse,
  PromotionsByBasePriceIdsResponse,
  PromotionWithBuyOneGiftOneDetailsResponse,
  PromotionWithRecurlyDetailsResponse,
  RecurlyCouponResponse,
} from "../types/dto/response/promotionV2";
import {
  transformPromotionBulkModal,
  transformPromotionDetailsWithBuyOneGiftOne,
  transformPromotionDetailsWithRecurly,
  transformPromotionList,
  transformPromotionWithBuyOneGiftOneFormDataRequest,
  transformPromotionWithRecurlyFormDataRequest,
  transformRecurlyCouponModel,
  transformSubscriptionPromotions,
} from "../types/transformers/promotionV2";
import { PromotionListRequest } from "../types/dto/request/promotionV2";
import { getOffset } from "../utils/getPagination";

export async function fetchAllValidRecurlyCoupons(): Promise<RecurlyCouponModel[]> {
  const url = "/v2/coupons/recurly-coupon";
  return eComInstance.get<RecurlyCouponResponse[]>(url).then((res) => transformRecurlyCouponModel(res));
}

export async function createPromotionWithRecurly(promotion: PromotionWithRecurlyFormData) {
  const url = "/v2/coupons/recurly";
  return eComInstance.post(url, transformPromotionWithRecurlyFormDataRequest(promotion)).then((res) => res);
}

export async function createPromotionWithBuyOneGiftOne(promotion: PromotionWithBuyOneGiftOneFormData) {
  const url = "/v2/coupons/buy-one-gift-one";
  return eComInstance.post(url, transformPromotionWithBuyOneGiftOneFormDataRequest(promotion)).then((res) => res);
}

export async function fetchPromotionList(params: PromotionListRequest) {
  const url = "/v2/coupons";
  return eComInstance
    .get<PromotionListResponse>(url, { params })
    .then((res) => transformPromotionList(res));
}

export async function fetchPromotionWithBuyOneGiftOneDetails(
  id: string
): Promise<PromotionWithBuyOneGiftOneDetailsModal> {
  const url = `/v2/coupons/buy-one-gift-one/${id}`;
  return eComInstance.get<PromotionWithBuyOneGiftOneDetailsResponse>(url).then((res) => {
    return transformPromotionDetailsWithBuyOneGiftOne(res);
  });
}

export async function fetchPromotionWithRecurlyDetails(id: string): Promise<PromotionWithRecurlyDetailsModal> {
  const url = `/v2/coupons/recurly/${id}`;
  return eComInstance.get<PromotionWithRecurlyDetailsResponse>(url).then((res) => {
    return transformPromotionDetailsWithRecurly(res);
  });
}

export async function updatePromotionWithRecurly(id: string, promotion: PromotionWithRecurlyFormData) {
  const url = `/v2/coupons/recurly/${id}`;
  return eComInstance.put(url, transformPromotionWithRecurlyFormDataRequest(promotion)).then((res) => res);
}

export async function updatePromotionWithBuyOneGiftOne(id: string, promotion: PromotionWithBuyOneGiftOneFormData) {
  const url = `/v2/coupons/buy-one-gift-one/${id}`;
  return eComInstance.put(url, transformPromotionWithBuyOneGiftOneFormDataRequest(promotion)).then((res) => res);
}

export async function deletePromotion(id: string): Promise<void> {
  const url = `/v2/coupons/${id}`;
  return eComInstance.delete(url).then((res) => res);
}

export async function fetchPromotionBulkCodeList(
  id: string,
  hideRedeemed: boolean,
  page: number = 1,
  pageSize: number = 10
): Promise<PromotionBulkListModel> {
  const url = `v2/coupons/recurly/${id}/single-uses`;
  return eComInstance
    .get<PromotionBulkListResponse>(url, {
      params: { hide_redeemed: hideRedeemed, limit: pageSize, offset: getOffset(page, pageSize) },
    })
    .then((res) => ({
      list: res.data.map((data) => transformPromotionBulkModal(data)),
      redeemedCount: res.redeemed_count,
    }));
}

export async function downloadPromotionCodeList(id: string, hideRedeemed: boolean): Promise<Blob> {
  const url = `v2/coupons/recurly/${id}/single-uses/csv`;
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return eComInstance.get(url, {
    params: { hide_redeemed: hideRedeemed, timezone: localTimeZone },
    responseType: "blob",
  });
}

export async function fetchPromotionsByBasePriceIds(basePriceIds: string[]) {
  const url = "/v2/coupons";
  return eComInstance
    .get<PromotionsByBasePriceIdsResponse>(url, { params: { for_save_offer: true, base_price_ids: basePriceIds } })
    .then((res) => transformSubscriptionPromotions(res.data));
}
