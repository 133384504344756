import React, { useEffect, useRef, useState } from "react";
import { Button, Checkbox, Space, Spin, Table } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useParams } from "react-router-dom";
import { EComModal } from "../../../../components/EComModal";
import styles from "./PromotionCodeList.module.scss";
import * as promotionAPI from "../../../../services/promotionV2";
import { saveFile } from "../../../../utils/saveFile";
import { message } from "../../../../components";
import { getPromotionBulkCodeListTableColumns } from "./getPromotionBulkCodeListTableColumns";
import { PromotionBulkListModel, PromotionStatus } from "../../../../types/model/promotionV2";

interface PromotionCodeListProps {
  code: string;
  count: number;
  status: PromotionStatus;
}

interface TableConfig {
  current?: number;
  pageSize?: number;
  hideRedeem: boolean;
}

const DEFAULT_PAGE_CONFIG = {
  current: 1,
  pageSize: 10,
  hideRedeem: false,
};

export default function PromotionCodeList({ count, code, status }: PromotionCodeListProps) {
  const { id } = useParams<{ id: string }>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [dataSource, setDataSource] = useState<PromotionBulkListModel>();
  const [pageConfig, setPageConfig] = useState<TableConfig>(DEFAULT_PAGE_CONFIG);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (isModalVisible) {
      setIsLoadingData(true);
      promotionAPI
        .fetchPromotionBulkCodeList(id, pageConfig.hideRedeem, pageConfig.current, pageConfig.pageSize)
        .then((res) => setDataSource(res))
        .finally(() => setIsLoadingData(false));
    }
  }, [isModalVisible, pageConfig]);

  const closeModal = () => {
    setConfirmLoading(false);
    setIsModalVisible(false);
  };

  const onViewClick = () => {
    setIsModalVisible(true);
    setPageConfig(DEFAULT_PAGE_CONFIG);
  };

  const onDownload = () => {
    setConfirmLoading(true);
    promotionAPI
      .downloadPromotionCodeList(id, pageConfig.hideRedeem)
      .then((content: Blob) => saveFile(content, `promotion_code_list_${code}.csv`))
      .catch(message.error)
      .finally(closeModal);
  };

  const onPageChange = (current: number, pageSize?: number) => {
    setPageConfig({ ...pageConfig, current, pageSize });
  };

  const onHideRedeemChange = (e: CheckboxChangeEvent) => {
    setPageConfig({ ...pageConfig, hideRedeem: e.target.checked });
  };

  return (
    <>
      <Button ref={buttonRef} type={"link"} className={styles.viewCodesButton} onClick={onViewClick}>
        View and download a batch of promotion codes here
      </Button>
      <EComModal
        visible={isModalVisible}
        title="Promotion code list"
        onCancel={closeModal}
        okText="Download"
        confirmLoading={confirmLoading}
        onOk={onDownload}
        width="75%"
        footer={[
          <Button
            type="primary"
            shape={"round"}
            style={{ minWidth: "92px" }}
            loading={confirmLoading}
            onClick={onDownload}
          >
            Download
          </Button>,
        ]}
      >
        <Spin spinning={isLoadingData} size={"large"}>
          <Space className={styles.subtitleContainer} size={"large"}>
            {`There are ${dataSource?.redeemedCount ?? 0}/${count} promotion codes redeemed already.`}
            <Checkbox checked={pageConfig.hideRedeem} onChange={onHideRedeemChange}>
              Hide redeemed codes
            </Checkbox>
          </Space>
          <Table
            className={styles.table}
            columns={getPromotionBulkCodeListTableColumns()}
            dataSource={dataSource?.list}
            rowKey={(row) => row.code}
            pagination={{ ...pageConfig, total: count, onChange: onPageChange }}
          />
        </Spin>
      </EComModal>
    </>
  );
}
