import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import styles from "./index.module.scss";
import { RootState, useAppDispatch } from "../../../app/store";
import { fetchGift } from "../customerSlice";
import UpdateRecipientEmailModal from "./components/UpdateRecipientEmailModal";
import SendEmailModal from "./components/SendEmailModal";
import useAppAuth0 from "../../../hooks/useAppAuth0";
import { givenGiftColumnConfig, receivedGiftColumnConfig } from "./columnConfig";

function GiftComponent({ auth0Id }: { auth0Id: string }) {
  const dispatch = useAppDispatch();
  const { user } = useAppAuth0();
  const [isUpdateRecipientEmailModalVisible, setIsUpdateRecipientEmailModalVisible] = useState(false);
  const [isSendEmailModalVisible, setIsSendEmailModalVisible] = useState(false);
  const [givenEmail, setGivenEmail] = useState<{ id: string; email: string }>({ id: "", email: "" });
  const {
    customerGift: { givenList, receivedList },
  } = useSelector((state: RootState) => state.customer);

  useEffect(() => {
    dispatch(fetchGift({ auth0Id: auth0Id })).then(unwrapResult);
  }, [auth0Id, dispatch]);

  const refreshGivenGift = () => {
    dispatch(fetchGift({ auth0Id: auth0Id })).then(unwrapResult);
  };

  return (
    <div className={styles.giftSection}>
      <header className={styles.subtitle}>Gift given</header>
      <Table
        scroll={{ x: "max-content" }}
        columns={givenGiftColumnConfig(
          user.canUpdateGift,
          setIsUpdateRecipientEmailModalVisible,
          setIsSendEmailModalVisible,
          setGivenEmail
        )}
        dataSource={givenList}
        rowKey={(row) => row.id}
        pagination={false}
      />
      <header className={styles.subtitle}>Gift received</header>
      <Table
        scroll={{ x: "max-content" }}
        columns={receivedGiftColumnConfig}
        dataSource={receivedList}
        rowKey={(row) => row.id}
        pagination={false}
      />
      {isUpdateRecipientEmailModalVisible && (
        <UpdateRecipientEmailModal
          visible={isUpdateRecipientEmailModalVisible}
          givenEmail={givenEmail}
          closeModal={() => setIsUpdateRecipientEmailModalVisible(false)}
          onFinish={refreshGivenGift}
        />
      )}
      <SendEmailModal
        visible={isSendEmailModalVisible}
        givenEmail={givenEmail}
        closeModal={() => setIsSendEmailModalVisible(false)}
        onFinish={refreshGivenGift}
      />
    </div>
  );
}

export default GiftComponent;
