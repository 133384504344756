import React, { useEffect } from "react";
import { map } from "lodash";
import { Col, Form, FormInstance, Input, Radio, Row, Select, Space } from "antd";
import { DropDown } from "../../../../assets/icons";
import styles from "./SaveOfferForm.module.scss";
import { EMPTY } from "../../../../constants/common";
import { PromotionDiscountType, SubscriptionPromotion } from "../../../../types/model/promotionV2";
import { getDisplayDiscount, getDisplayDuration, getDisplayPromotionDiscountType } from "../../RecurlyPromotion/util";

interface Props {
  namePrefix: string;
  promotionDetail: SubscriptionPromotion;
  form: FormInstance;
  promotionCodeInput: React.ReactNode;
}

const resetPromotionDetailFields = (form: FormInstance, namePrefix: string) => {
  form.resetFields([
    `${namePrefix}Duration`,
    `${namePrefix}CountryIds`,
    `${namePrefix}PercentageDiscount`,
    `${namePrefix}AmountDiscount`,
    `${namePrefix}DiscountType`,
  ]);
};

const SaveOfferPromoDetails = ({ namePrefix, promotionDetail, form, promotionCodeInput }: Props) => {
  useEffect(() => {
    if (promotionDetail) {
      const {
        discountType,
        duration = undefined,
        appliedCountries = [],
        currencies = [],
        percent = undefined,
      } = promotionDetail;

      const countryNames = hasPromotionCountries() ? map(appliedCountries, "name") : EMPTY;
      const discount = getDisplayDiscount(discountType, percent, currencies);

      form.setFieldsValue({
        [`${namePrefix}Duration`]: getDisplayDuration(duration),
        [`${namePrefix}CountryIds`]: countryNames,
        [`${namePrefix}DiscountType`]: discountType,
        [`${namePrefix}AmountDiscount`]: discount,
        [`${namePrefix}PercentageDiscount`]: discount,
      });
    } else {
      resetPromotionDetailFields(form, namePrefix);
    }
  }, [namePrefix, promotionDetail]);

  const hasPromotionCountries = () => {
    return promotionDetail?.appliedCountries && promotionDetail?.appliedCountries?.length > 0;
  };

  return (
    <>
      <Row gutter={32}>
        <Col span={6}>{promotionCodeInput}</Col>
        <Col span={6}>
          <Form.Item label="Duration">
            <Space size={22} align="baseline" className={styles.littleInput}>
              <Form.Item noStyle name={`${namePrefix}Duration`}>
                <Input disabled={true} />
              </Form.Item>
            </Space>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Applied country/region"
            name={`${namePrefix}CountryIds`}
            className={hasPromotionCountries() ? styles.largeFormItem : undefined}
          >
            <Select
              mode={hasPromotionCountries() ? "multiple" : undefined}
              disabled
              showArrow
              suffixIcon={<DropDown />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={6}>
          <Form.Item label="Promotion type" name={`${namePrefix}DiscountType`}>
            <Radio.Group disabled>
              {Object.values(PromotionDiscountType)
                .filter((item) => item !== PromotionDiscountType.BUY_ONE_GIFT_ONE)
                .map((type) => (
                  <Radio value={type} key={type}>
                    {getDisplayPromotionDiscountType(type)}
                  </Radio>
                ))}
            </Radio.Group>
          </Form.Item>
        </Col>
        <Form.Item noStyle shouldUpdate>
          {() =>
            promotionDetail.discountType === PromotionDiscountType.AMOUNT_OFF ? (
              <Col span={18}>
                <Form.Item label="Discount" name={`${namePrefix}AmountDiscount`}>
                  <Input disabled />
                </Form.Item>
              </Col>
            ) : (
              <Col span={6}>
                <Form.Item label="Discount" name={`${namePrefix}PercentageDiscount`}>
                  <Input disabled addonAfter="%" />
                </Form.Item>
              </Col>
            )
          }
        </Form.Item>
      </Row>
    </>
  );
};

export default SaveOfferPromoDetails;
