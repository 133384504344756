import { DeltaStatic } from "quill";
import { LocaleType } from "../../utils/locale";
import { SubscriptionPromotion } from "./promotionV2";

export enum AppliedStage {
  IN_FREE_TRIAL = "In Free Trial",
  ONE_TO_THREE_MONTHS = "1-3 Months of use",
  THREE_TO_SIX_MONTHS = "3-6 Months of use",
  SIX_MONTHS_TO_ONE_YEAR = "6 Months to 1 year",
  ONE_YEAR_PLUS = "1 Year+",
}

export enum SaveOfferType {
  PROMOTION = "Promotion",
  SWITCH_PLAN = "Switch plan",
  PAUSE = "Pause",
  EXTEND_BILLING_DATE = "Extend billing date",
}

export enum EffectiveDate {
  IMMEDIATELY = "Immediately",
  NEXT_BILLING_CYCLE = "Next Billing Cycle",
}

export enum PromotionNamePrefix {
  BasePrice = "basePrice",
  Plan = "plan",
}

export interface SaveOfferModel {
  id: string;
  cancelReason?: {
    id: string;
    code: number;
    description: string;
  };
  appliedStages: AppliedStage[];
  appliedBasePrices: {
    id: string;
    name: string;
  }[];
  type: SaveOfferType;
  updatedAt: string;
}

export interface SaveOfferListModel {
  list: SaveOfferModel[];
  total: number;
}

export interface CancelReasonModal {
  key: string;
  code?: number;
  id?: string;
  description: {
    en: string;
    es?: string;
  };
  isUsed?: boolean;
}

export interface SaveOfferFormData {
  cancelReasonCode: number;
  appliedStages: AppliedStage[];
  appliedBasePriceIds: string[];
  saveOfferType: SaveOfferType;
  basePricePromotionCode?: string;
  effectiveDate?: EffectiveDate;
  switchToPlanPid?: string;
  planPromotionCode?: string;
  extendDays?: string;
}

export interface SaveOfferDetailModel extends SaveOfferModel {
  contents: SaveOfferContent[];
  effectiveDate?: EffectiveDate;
  extendDays?: number;
  promotion?: SubscriptionPromotion;
  switchToPlan?: {
    pid: string;
    title: string;
    billingPlanId: string;
    billingPlanName?: string;
  };
}

export interface SaveOfferContent {
  label: string;
  key: LocaleType;
  locale: LocaleType;
  title: string;
  description: DeltaStatic;
  required: boolean;
}
