import React from "react";
import styles from "./index.module.scss";
import ItemField from "../components/ItemField";
import { getDisplayDiscount, getDisplayDuration } from "../../RecurlyPromotion/util";
import { EMPTY } from "../../../../constants/common";
import { PromotionDiscountType, SubscriptionPromotion } from "../../../../types/model/promotionV2";

interface Props {
  promotion: SubscriptionPromotion;
}

export default function ViewSaveOfferPromoDetails({ promotion }: Props) {
  const {
    code,
    discountType,
    duration = undefined,
    appliedCountries = [],
    currencies = [],
    percent = undefined,
  } = promotion;

  return (
    <>
      <div className={`${styles.sectionContent} ${styles.small}`}>
        <ItemField label="Promotion code:" value={code} />
        <ItemField label="Duration" value={getDisplayDuration(duration)} />
        <ItemField
          label="Applied country/region:"
          value={appliedCountries?.length > 0 ? appliedCountries.map((x) => x.name).join(", ") : EMPTY}
        />
      </div>
      <div className={`${styles.sectionContent} ${styles["small-25-75"]}`}>
        <ItemField
          label="Promotion type:"
          value={discountType === PromotionDiscountType.PERCENTAGE_OFF ? "Percentage off" : "Amount off"}
        />
        <ItemField label="Discount:" value={getDisplayDiscount(discountType, percent, currencies)} />
      </div>
    </>
  );
}
