import { useSelector } from "react-redux";
import { RootState } from "app/store";
import { FormInstance } from "antd/es/form";

export function useCombinedProductInfo() {
  const { productInfo, allAccessInfo } = useSelector((state: RootState) => state.addProduct);
  return [...allAccessInfo, ...productInfo];
}

export function useUpgradeEComProducts(form: FormInstance) {
  const productType = form.getFieldValue("productType");
  const { eComPremiumProducts } = useSelector((state: RootState) => state.addProduct);

  return productType === "FREE"
    ? []
    : eComPremiumProducts.filter((product) => product.contentProductType === "Upgrade");
}
