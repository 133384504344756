import React, { forwardRef, useImperativeHandle } from "react";
import { Button, Form, Spin } from "antd";
import Icon from "@ant-design/icons";
import { Minus, Plus } from "../../assets/icons";
import styles from "./index.module.scss";
import message from "../message";

interface Props {
  form: any;
  headerTitles: string[];
  buttonTitle: string;
  successMessage: string;
  updateData: (values: any) => Promise<void>;
  refreshData: () => Promise<void>;
  renderField: (index: number) => JSX.Element;
  allowRemove: (index: number) => boolean;
  isLoading: boolean;
}

const GenericConfigureForm = forwardRef((props: Props, ref) => {
  useImperativeHandle(ref, () => ({
    validate,
    update,
    refresh,
  }));

  const validate = async (): Promise<boolean> => {
    try {
      const values = await props.form.validateFields();
      return Object.values(values).length > 0;
    } catch (e) {
      return false;
    }
  };

  const update = async (): Promise<void> => {
    try {
      const values = await props.form.validateFields();
      await props.updateData(values);
      message.success(props.successMessage, 2);
    } catch (error) {}
  };

  const refresh = async () => {
    await props.refreshData();
  };

  return (
    <Spin spinning={props.isLoading} size={"small"}>
      <div className={styles.configureForm}>
        <div className={styles.header}>
          {props.headerTitles.map((title: String, index: number) => (
            <span key={index}>{title}:</span>
          ))}
        </div>
        <Form form={props.form} onFinish={update}>
          <Form.List name="fields">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => {
                    return (
                      <div key={field.key} className={styles.fieldContainer}>
                        {props.renderField(index)}
                        {props.allowRemove(index) && (
                          <Icon
                            style={{ marginLeft: 8 }}
                            aria-label="delete"
                            data-testid="delete-row"
                            component={Minus}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                  <Form.Item>
                    <Button
                      type="text"
                      icon={<Icon component={Plus} />}
                      onClick={add}
                      className={styles.addButton}
                      data-testid={`add-button`}
                      aria-label={`add button`}
                    >
                      Add {props.buttonTitle}
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </Form>
      </div>
    </Spin>
  );
});

export default GenericConfigureForm;
