import ItemField from "../components/ItemField";
import styles from "./index.module.scss";

interface Props {
  cancelReason: string;
  appliedStage: string;
  appliedBasePrice: string;
}

export default function ApplicationRules({ cancelReason, appliedStage, appliedBasePrice }: Props) {
  return (
    <div className={styles.content}>
      <span className={styles.sectionTitle}>Application rules</span>
      <div className={styles.detailSection}>
        <div className={styles.sectionContent}>
          <ItemField label="Applied cancel reason" value={cancelReason} />
          <ItemField label="Applied stage" value={appliedStage} />
          <ItemField label="Applied Base price" value={appliedBasePrice} />
        </div>
      </div>
    </div>
  );
}
