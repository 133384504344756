import { useHistory } from "react-router";
import React, { useState } from "react";
import moment from "moment";
import { Button, Card, Form, Space } from "antd";
import { Prompt } from "react-router-dom";
import * as promotionAPI from "services/promotionV2";
import { DATETIME_FORMAT_WITH_00_SECOND, LEAVE_CONFIRMATION } from "../../../../constants/common";
import { PromotionForm, PromotionSource } from "../components/PromotionForm";
import { message } from "../../../../components";
import {
  DescriptionDisclosure,
  PromotionWithBuyOneGiftOneFormData,
  PromotionWithRecurlyFormData,
} from "../../../../types/model/promotionV2";
import { transformDescriptionDisclosure } from "../util";

function AddNewPromotion() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [descriptionDisclosures, setDescriptionDisclosures] = useState<DescriptionDisclosure[]>([]);
  const [formIsHalfFilledOut, setFormIsHalfFilledOut] = useState(true);
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  function createPromotionHandler<T>(fetcher: (promotion: T) => Promise<any>, values: T) {
    setIsSaveLoading(true);
    fetcher(values)
      .then(() => {
        setFormIsHalfFilledOut(false);
        form.resetFields();
        history.push("/marketing/recurly-promotion");
        message.success("Promotion added successfully.").then();
      })
      .finally(() => setIsSaveLoading(false));
  }

  const handleCreate = () =>
    form.validateFields().then((values) => {
      if (values.source === PromotionSource.RECURLY) {
        createPromotionHandler<PromotionWithRecurlyFormData>(promotionAPI.createPromotionWithRecurly, {
          ...values,
          descriptionDisclosures,
        });
      }
      if (values.source === PromotionSource.BUY_ONE_GIFT_ONE) {
        createPromotionHandler<PromotionWithBuyOneGiftOneFormData>(promotionAPI.createPromotionWithBuyOneGiftOne, {
          ...values,
          description: transformDescriptionDisclosure(values, "DescriptionContent"),
          disclosure: transformDescriptionDisclosure(values, "DisclosureContent"),
          timeRange: values.timeRange.map((date: moment.Moment) => date.format(DATETIME_FORMAT_WITH_00_SECOND)),
        });
      }
    });

  const handleCancel = () => {
    setDescriptionDisclosures([]);
    history.push("/marketing/recurly-promotion");
  };

  return (
    <Card
      title="Add new promotion"
      extra={
        <Space size={10}>
          <Button type="default" shape="round" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="primary" shape="round" onClick={handleCreate} loading={isSaveLoading}>
            Save
          </Button>
        </Space>
      }
      className="page-container"
    >
      <Prompt when={formIsHalfFilledOut} message={LEAVE_CONFIRMATION} />
      <PromotionForm
        form={form}
        descriptionDisclosures={descriptionDisclosures}
        updateDescriptionDisclosures={setDescriptionDisclosures}
      />
    </Card>
  );
}

export default AddNewPromotion;
