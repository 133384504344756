import { eComInstance } from "../utils/instance";
import { CancelReasonResponse, SaveOfferDetailResponse, SaveOfferListResponse } from "../types/dto/response/saveOffer";
import { CancelReasonModal, SaveOfferContent, SaveOfferFormData } from "../types/model/saveOffer";
import {
  transformCancelReasonList,
  transformSaveOfferDetail,
  transformSaveOfferFormDataRequest,
  transformSaveOfferList,
  transformSaveOfferListRequest,
} from "../types/transformers/saveOffer";
import { SaveOfferListRequest } from "../types/dto/request/saveOffer";

export async function updateCancelReason(dataSources: CancelReasonModal[]) {
  const url = "/cancel-reason";
  return eComInstance.put(
    url,
    dataSources.flatMap(({ id, description }) => ({
      id,
      description: description,
    }))
  );
}

export async function fetchCancelReason() {
  const url = "/cancel-reason";
  return eComInstance.get<CancelReasonResponse>(url).then((res) => transformCancelReasonList(res.data));
}

export async function fetchSaveOfferList(params: SaveOfferListRequest) {
  const url = "/save-offers";
  return eComInstance
    .get<SaveOfferListResponse>(url, {
      params: transformSaveOfferListRequest(params),
    })
    .then((res) => transformSaveOfferList(res));
}

export async function createSaveOffer(saveOffer: SaveOfferFormData, contents: SaveOfferContent[]) {
  const url = "v2/save-offers";
  return eComInstance.post(url, transformSaveOfferFormDataRequest(saveOffer, contents)).then((res) => res);
}

export async function fetchSaveOfferDetail(id: string) {
  const url = `v2/save-offers/${id}`;
  return eComInstance.get<SaveOfferDetailResponse>(url).then((res) => transformSaveOfferDetail(res));
}

export async function deleteSaveOffer(id: string) {
  const url = `v2/save-offers/${id}`;
  return eComInstance.delete(url);
}

export async function updateSaveOffer(id: string, saveOffer: SaveOfferFormData, contents: SaveOfferContent[]) {
  const url = `v2/save-offers/${id}`;
  return eComInstance.put(url, transformSaveOfferFormDataRequest(saveOffer, contents)).then((res) => res);
}
