import { Divider } from "antd";
import ViewSaveOfferPromoDetails from "./ViewSaveOfferPromoDetails";
import styles from "./index.module.scss";
import { SubscriptionPromotion } from "../../../../types/model/promotionV2";
import ItemField from "../components/ItemField";

interface Props {
  effectiveDate: string;
  switchToPlanTitle: string;
  promotion?: SubscriptionPromotion;
}

export default function SwitchPlanTypeContent({ effectiveDate, switchToPlanTitle, promotion }: Props) {
  return (
    <>
      <div className={`${styles.detailSection}`}>
        <div className={`${styles.sectionContent} ${styles.full}`}>
          <ItemField label="Save offer type:" value="Switch plan" />
        </div>
      </div>
      <Divider />
      <div className={`${styles.detailSection} ${styles.multiple}`}>
        <div className={`${styles.sectionContent} ${styles.full}`}>
          <ItemField label="Effective Date:" value={effectiveDate} />
        </div>
        <div className={`${styles.sectionContent} ${styles.full}`}>
          <ItemField label="Switch to plan:" value={switchToPlanTitle} />
        </div>
        {promotion && <ViewSaveOfferPromoDetails promotion={promotion} />}
      </div>
    </>
  );
}
