import { ConfigProvider, Form, Input, message } from "antd";
import { useState } from "react";
import _ from "lodash";
import { EComModal } from "../../../../../components/EComModal";
import { validateMessages } from "../../../../../constants/common";
import modalStyles from "../../../../../components/EComModal/index.module.scss";
import * as CustomerAPI from "../../../../../services/customer";
import { fetchSubscriptions } from "../../../../../services/subscription";

interface IEditEmailModalProps {
  customerId: number;
  customerAuth0Id: string;
  visible: boolean;
  originEmail: string;
  onFinish: () => void;
  closeModal: () => void;
}

export default function EditUserEmailModal({
  customerId,
  customerAuth0Id,
  visible,
  originEmail,
  onFinish,
  closeModal,
}: IEditEmailModalProps) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit() {
    setIsLoading(true);
    try {
      const values = await form.validateFields();
      await CustomerAPI.updateUserEmail(customerId, customerAuth0Id, values.email);

      message.success("Update successful");
      const subs = await fetchSubscriptions(customerAuth0Id);
      if (subs.length > 0) {
        await CustomerAPI.syncUserToSF(
          originEmail,
          values.email,
          _.get(subs, [0, "paymentMethod", "billingAddress", "country"], "")
        );
      }
      onFinish();
      closeModal();
    } catch (e) {
      message.error(e.message());
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <EComModal
      title="Edit email"
      visible={visible}
      okText="Confirm"
      onCancel={closeModal}
      onOk={onSubmit}
      style={modalStyles.modal}
      confirmLoading={isLoading}
    >
      <ConfigProvider form={{ validateMessages }}>
        <Form className={modalStyles.form} form={form}>
          <Form.Item
            label="New email:"
            name="email"
            required
            className={modalStyles.formItem}
            rules={[
              { required: true },
              {
                type: "email",
                message: "Please check the format of your email and try again.",
              },
              () => ({
                validator(_, value) {
                  if (!value || value !== originEmail) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("This email is already taken. Please choose a different email."));
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Confirm new email:"
            name="cormfirmNewEmail"
            required
            className={modalStyles.formItem}
            dependencies={["email"]}
            rules={[
              { required: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("email") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The email addresses you entered are different. Please check and try again.")
                  );
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </ConfigProvider>
    </EComModal>
  );
}
