import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Row, Space } from "antd";
import styles from "./PaymentMethod.module.scss";
import { Edit } from "../../../assets/icons";
import {
  getCountryName,
  getPaymentMethodTextByPaymentMethod,
  PaymentMethod as PaymentMethodModel,
} from "../../../types/model/paymentMethod";
import EditBillingAddressModal from "../CustomerDetail/components/modal/EditBillingAddressModal";
import { EMPTY } from "../../../constants/common";
import useAppAuth0 from "../../../hooks/useAppAuth0";
import { Country } from "../../../types/model/price";
import { fetchAllCountries } from "../../../services/basePrice";

export default function PaymentMethod({
  auth0Id,
  paymentMethod,
  onFinishEditing,
}: {
  auth0Id: string;
  paymentMethod: PaymentMethodModel;
  onFinishEditing: () => void;
}) {
  const { user } = useAppAuth0();
  const [editBillingAddressVisible, setEditBillingAddressVisible] = useState(false);

  const { district, postalCode, country } = paymentMethod.billingAddress || {};

  const [countries, setCountries] = useState([] as Country[]);
  useEffect(() => {
    fetchAllCountries().then((data) => setCountries(data));
  }, []);

  return (
    <Space size={24} direction={"vertical"} className={styles.paymentMethodSection}>
      <header className={styles.subtitle}>Payment information</header>
      <Row gutter={32}>
        <Col span={12}>
          <div className={styles.paymentMethodItem}>
            <p>Payment information</p>
            <p>{getPaymentMethodTextByPaymentMethod(paymentMethod)}</p>
          </div>
        </Col>
        <Col span={12}>
          <div className={styles.paymentMethodItem}>
            <p>Billing address:</p>
            {paymentMethod.billingAddress ? (
              <span style={{ display: "flex", columnGap: "4px" }}>
                <p>
                  {(district ? `${district}, ` : "") + `${postalCode}, ${getCountryName(country ?? "", countries)}`}
                </p>
                {user.canUpdateB2CCustomerInfo && (
                  <Button
                    type="text"
                    shape="circle"
                    size="small"
                    onClick={() => {
                      setEditBillingAddressVisible(true);
                    }}
                  >
                    <Edit />
                  </Button>
                )}
              </span>
            ) : (
              <p>{EMPTY}</p>
            )}
          </div>
        </Col>
      </Row>
      <Divider />
      {paymentMethod.billingAddress && editBillingAddressVisible && (
        <EditBillingAddressModal
          auth0Id={auth0Id}
          visible
          billingAddress={paymentMethod.billingAddress}
          onFinish={onFinishEditing}
          closeModal={() => setEditBillingAddressVisible(false)}
        />
      )}
    </Space>
  );
}
