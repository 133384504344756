import { orderBy } from "lodash";
import { eComInstance } from "../utils/instance";
import {
  getTransformedUpdatedAssociations,
  getTransformedUpdatedBasePrice,
  transformBasePriceList,
  transformCountry,
  transformCurrency,
  transformCurrencyAssociation,
  transformPlanList,
  transformPlanListParams,
} from "../types/transformers/basePrice";
import { BasePriceListRequest } from "../types/dto/request/basePrice";
import {
  BasePriceListResponse,
  CountryResponse,
  CurrencyAssociationResponse,
  CurrencyResponse,
  PlanListResponse,
} from "../types/dto/response/basePrice";
import { ActiveBasePriceModel, BasePriceFieldValue, Country, CurrencyFormValue } from "../types/model/price";

export async function fetchBasePriceList(params: BasePriceListRequest) {
  const url = "/base-prices";
  return eComInstance
    .get<BasePriceListResponse>(url, { params })
    .then((res) => transformBasePriceList(res));
}

export async function fetchActiveBasePriceList() {
  const url = "/base-prices/actives";
  return eComInstance.get<ActiveBasePriceModel[]>(url).then((res) => res);
}

export async function fetchCurrencyAssociation() {
  const url = "/currency-associations";
  return eComInstance.get<CurrencyAssociationResponse[]>(url).then((res) => {
    return res.map((item) => transformCurrencyAssociation(item));
  });
}

export async function fetchBasePriceDetail(params: { id: string; limit: number; offset: number }) {
  const url = "/variants";
  const planListParams = transformPlanListParams(params);
  return eComInstance
    .get<PlanListResponse>(url, { params: planListParams })
    .then((res) => transformPlanList(res));
}

export async function fetchAllCurrencies(params: BasePriceListRequest) {
  const url = "/currencies";
  return eComInstance
    .get<CurrencyResponse[]>(url, { params })
    .then((res) => transformCurrency(res));
}

export async function fetchAllCountries() {
  const countries = sessionStorage.getItem("countries");
  if (!countries) {
    const url = "/countries";
    return eComInstance.get<CountryResponse[]>(url).then((res) => {
      const countries = orderBy(transformCountry(res), ["displayName"], ["asc"]);
      sessionStorage.setItem("countries", JSON.stringify(countries));
      return countries;
    });
  }

  return JSON.parse(countries) as Country[];
}

export async function updateCurrencyAssociations(values: CurrencyFormValue, allCountries: Country[]) {
  const url = "/currency-associations";
  return eComInstance.put(url, getTransformedUpdatedAssociations(values, allCountries));
}

export async function createBasePrice(name: string) {
  const url = "/base-prices";
  return eComInstance.post(url, { name });
}

export async function updateBasePrice(value: BasePriceFieldValue) {
  const url = `/base-prices/${value.id}`;
  return eComInstance.put(url, getTransformedUpdatedBasePrice(value));
}

export async function deleteBasePrice(id: string) {
  const url = `/base-prices/${id}`;
  return eComInstance.delete(url);
}
