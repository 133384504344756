import { ColumnType } from "antd/lib/table";
import { EMPTY } from "../../../../constants/common";
import { PromotionBulkModel, PromotionBulkStatus } from "../../../../types/model/promotionV2";

export const getPromotionBulkCodeListTableColumns = (): ColumnType<PromotionBulkModel>[] => {
  return [
    {
      title: "Promotion code",
      dataIndex: "code",
      width: "25%",
      ellipsis: true,
      render: (value) => value || EMPTY,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "25%",
      ellipsis: true,
      render: (value: PromotionBulkStatus) => value || EMPTY,
    },
    {
      title: "Redeemed email",
      dataIndex: "email",
      width: "25%",
      ellipsis: true,
      render: (_, record) => {
        if (!record.customerId) {
          return EMPTY;
        }
        return <a href={`/customers/b2c/${record.customerId}`}>{record.email ?? "Anonymous user"}</a>;
      },
    },
    {
      title: "Redeemed date",
      dataIndex: "date",
      width: "25%",
      ellipsis: true,
      render: (value) => value || EMPTY,
    },
  ];
};
