import React from "react";
import { Card, Table } from "antd";
import _ from "lodash";

const { REACT_APP_LEARN_WEB_URL } = process.env;

const deepLinksMapping = {
  "Me Tab": `${REACT_APP_LEARN_WEB_URL}/landingpage?type=2&category=me`,
  Reading: `${REACT_APP_LEARN_WEB_URL}/landingpage?type=2&category=reading`,
  Skills: `${REACT_APP_LEARN_WEB_URL}/landingpage?type=2&category=skills`,
  "Bonus Packs": `${REACT_APP_LEARN_WEB_URL}/landingpage?type=2&category=bonus_pack`,
  "Flash Cards": `${REACT_APP_LEARN_WEB_URL}/landingpage?type=2&category=flash_card`,
  "Quick Match": `${REACT_APP_LEARN_WEB_URL}/landingpage?type=2&category=quick_match`,
  "Speak Easy": `${REACT_APP_LEARN_WEB_URL}/landingpage?type=2&category=speak_easy`,
  "Speak Round": `${REACT_APP_LEARN_WEB_URL}/landingpage?type=2&category=speed_round`,
  "My Vocabulary": `${REACT_APP_LEARN_WEB_URL}/landingpage?type=2&category=vocabulary`,
  "Smart Tutor": `${REACT_APP_LEARN_WEB_URL}/landingpage?type=2&category=smart_tutor`,
  "Mini Lesson": `${REACT_APP_LEARN_WEB_URL}/landingpage?type=2&category=mini_lesson`,
  "Set Reminder": `${REACT_APP_LEARN_WEB_URL}/landingpage?type=2&category=set_reminder`,
};

const DeeplinkList = () => {
  const columns = [
    {
      title: "In-app feature",
      dataIndex: "premiumFeature",
      width: "30%",
    },
    {
      title: "Deeplink",
      dataIndex: "deeplink",
      ellipsis: true,
      render: (value: string) => (
        <a target="_blank" rel="noopener noreferrer" href={value}>
          {value}
        </a>
      ),
    },
  ];

  const dataSource = _.map(deepLinksMapping, (deeplink, premiumFeature) => {
    return {
      premiumFeature,
      deeplink,
    };
  });

  return (
    <Card title="Deeplink list" className="page-container">
      <Table columns={columns} dataSource={dataSource} rowKey={(row) => row.deeplink} pagination={false} />
    </Card>
  );
};

export default DeeplinkList;
