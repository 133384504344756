import instance, { eComInstance } from "../utils/instance";
import { CustomerInfoResponse, CustomerResponse, GiftResponse } from "../types/dto/response/customer";
import Page from "../types/page";
import {
  CustomerListParams,
  InAppPurchaseRecordsParams,
  LearningProgressesParams,
} from "../types/dto/request/customer";
import { EntitlementModel } from "../types/model/entitlement";
import { DEFAULT_MAX_PAGINATION } from "../constants/common";
import {
  CustomerSubscriberInfo,
  GiftCardBalanceCurrencyItem,
  InAppPurchaseRecordModel,
  LearningProgressModel,
} from "../types/model/customer";
import { TransactionResponse, TransactionSource } from "../types/model/transactions";
import { transformTransactions } from "../types/transformers/transaction";
import { TransactionListRequest } from "../types/dto/request/transaction";
import { PaymentMethodDto } from "../types/dto/response/paymentMethod";
import { transformPaymentMethod } from "../types/transformers/user/paymentMethod";
import { BillingAddressUpdateRequest } from "../types/dto/request/paymentMethod";
import { transformGift } from "../types/transformers/gift";

export function fetchAll(params: CustomerListParams) {
  const url = "/customers";
  return instance.get<Page<CustomerResponse>>(url, { params });
}

export async function resetPassword(email: string) {
  const url = "/customers/reset-password";
  return instance.post(url, { email });
}

export function removeCustomer(id: number) {
  const url = `/customers/${id}`;
  return instance.delete<void>(url);
}

export async function updateUsername(
  customerId: number,
  auth0Id: string,
  name: { givenName: string; familyName: string }
) {
  const ecomUrl = `/v2/customer/${auth0Id}/info`;
  await eComInstance.put(ecomUrl, {
    firstName: name.givenName,
    lastName: name.familyName,
  });
  const url = `/customers/${customerId}`;
  return instance.put(url, {
    firstName: name.givenName,
    lastName: name.familyName,
  });
}

export async function updateUserEmail(customerId: number, auth0Id: string, email: string) {
  const ecomUrl = `/v2/customer/${auth0Id}/info`;
  await eComInstance.put(ecomUrl, {
    email: email,
  });
  const url = `/customers/${customerId}`;
  return instance.put(url, { email });
}

export async function fetchUserDefaultPaymentMethod(auth0Id: string) {
  const url = `/customer/${auth0Id}/payment-method`;
  return eComInstance.get<PaymentMethodDto>(url).then((res) => {
    return res ? transformPaymentMethod(res) : null;
  });
}

export async function fetchUserSubscriberInfo(email: string) {
  const url = `/customer/${email}/subscriber`;
  return eComInstance.get<CustomerSubscriberInfo>(url);
}

export async function updateUserSubscriberInfo(email: string, optIn: string) {
  const url = `/customer/${email}/subscriber`;
  return eComInstance.post(url, { email, optIn });
}

export async function updateUserDefaultPaymentMethodBillingAddress(
  auth0Id: string,
  billingAddress: BillingAddressUpdateRequest
) {
  const url = `/customer/${auth0Id}/payment-method/billing-address`;
  return eComInstance.put(url, { ...billingAddress });
}

export async function fetchGiftCardBalance(id: string) {
  const url = `/customer/${id}/credits`;
  return eComInstance.get<GiftCardBalanceCurrencyItem[]>(url);
}

export function updateGiftCardBalance({
  id,
  currency,
  deltaAmount,
}: {
  id: string;
  currency: string;
  deltaAmount: number;
}) {
  const url = `/customer/${id}/credits`;
  return eComInstance.post(url, {
    currency,
    delta_amount: deltaAmount,
  });
}

export async function fetchEntitlementList(customerId: number) {
  const url = "/entitlements";
  const params = {
    ...DEFAULT_MAX_PAGINATION,
    sort: "createdDate,DESC",
    customerId,
  };
  return instance.get<Page<EntitlementModel>>(url, { params });
}

export async function restoreEntitlement(customerId: number) {
  const url = "/entitlements/restore";
  return instance.post(url, { customerIds: [customerId] });
}

export async function fetchCustomerDetail(customerId: number) {
  const url = `/customers/${customerId}`;
  return instance.get<CustomerInfoResponse>(url);
}

export async function fetchLearningProgresses(params: LearningProgressesParams) {
  const { customerId, ...filter } = params;
  const url = `/customers/${customerId}/lesson-progresses`;
  return instance.get<Page<LearningProgressModel>>(url, { params: filter });
}

export async function fetchInAppPurchaseRecords(params: InAppPurchaseRecordsParams) {
  const url = `/iap-records`;
  return instance.get<Page<InAppPurchaseRecordModel>>(url, { params });
}

export async function fetchTransactions(params: TransactionListRequest) {
  const url = "/transactions";
  return eComInstance
    .get<TransactionResponse[]>(url, { params })
    .then((res) => transformTransactions(res));
}

export async function refundTransaction(
  auth0Id: string,
  transactionId: string,
  amount: number,
  source: TransactionSource
) {
  console.log(auth0Id);
  const url = `/transactions/${transactionId}/refunds`;
  return eComInstance.post(url, { amount, auth0Id, source });
}

export async function syncUserToSF(originEmail: string, email: string, countryCode: string) {
  const subscriber = await eComInstance.get(`/sf/subscriber?email=${originEmail}`);

  if (subscriber?.results?.status === "Active") {
    return Promise.all([
      eComInstance.post(`/sf/subscriber`, {
        oldEmail: originEmail,
        newEmail: email,
        country: countryCode,
      }),
      instance.post(`/customers/sync-sf`, {
        email,
        countryCode,
        source: "web",
        deviceLanguage: window.navigator.language,
      }),
    ]);
  }
}

export async function fetchGift(params: { auth0Id: string }) {
  const url = "/v2/gift";

  return eComInstance
    .get<GiftResponse>(url, { params })
    .then((res) => transformGift(res));
}

export function updateGivenGiftRecipientEmail(id: string, email: string) {
  const url = `/v2/gift/${id}/update-email`;
  return eComInstance.put(url, { recipient_email: email });
}

export function sendGivenGiftEmail(id: string) {
  const url = `/v2/gift/${id}/send-email`;
  return eComInstance.post(url);
}
