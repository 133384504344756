import { Button, Card, Divider, message } from "antd";
import { useHistory } from "react-router";
import React, { useState } from "react";
import { find, get } from "lodash";
import { SaveOfferDetailModel, SaveOfferType } from "../../../../types/model/saveOffer";

import styles from "./index.module.scss";
import ItemField from "../components/ItemField";
import { EMPTY } from "../../../../constants/common";
import * as saveOfferAPI from "../../../../services/saveOffer";
import { EComModal } from "../../../../components/EComModal";
import { LocaleType } from "../../../../utils/locale";
import useAppAuth0 from "../../../../hooks/useAppAuth0";
import ApplicationRules from "./ApplicationRules";
import ViewSaveOfferDetails from "./ViewSaveOfferDetails";
import ViewSaveOfferPromoDetails from "./ViewSaveOfferPromoDetails";
import SwitchPlanTypeContent from "./SwitchPlanTypeContent";

export default function ViewSaveOfferDetailContent({
  saveOfferDetail,
  onEdit,
}: {
  saveOfferDetail: SaveOfferDetailModel;
  onEdit: () => void;
}) {
  const history = useHistory();
  const { user } = useAppAuth0();
  const [isDeleteModalShowing, setIsDeleteModalShowing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const onDelete = () => {
    setIsDeleting(true);
    saveOfferAPI
      .deleteSaveOffer(saveOfferDetail.id)
      .then(() => {
        history.push("/marketing/save-offer");
        message.success("Save offer deleted successfully.").then();
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  return (
    <>
      <Card
        title={get(find(saveOfferDetail.contents, { locale: LocaleType.English }), "title") || EMPTY}
        extra={
          <div className={styles.detailActionContainer}>
            {user.canDeleteSaveOffer && (
              <Button type="default" shape="round" onClick={() => setIsDeleteModalShowing(true)}>
                Delete
              </Button>
            )}
            {user.canUpdateSaveOffer && (
              <Button type="default" shape="round" onClick={onEdit}>
                Edit
              </Button>
            )}
          </div>
        }
        className="page-container"
      >
        <ApplicationRules
          cancelReason={saveOfferDetail.cancelReason?.description || EMPTY}
          appliedStage={saveOfferDetail.appliedStages.join(", ")}
          appliedBasePrice={saveOfferDetail?.appliedBasePrices.map((x) => x.name).join(", ")}
        />
        <Divider />
        <ViewSaveOfferDetails content={saveOfferDetail.contents} />
        <Divider />
        {saveOfferDetail.type === SaveOfferType.PROMOTION && saveOfferDetail.promotion && (
          <>
            <div className={`${styles.detailSection}`}>
              <div className={`${styles.sectionContent} ${styles.full}`}>
                <ItemField label="Save offer type:" value="Promotion" />
              </div>
            </div>
            <Divider />
            <div className={`${styles.detailSection} ${styles.multiple}`}>
              <ViewSaveOfferPromoDetails promotion={saveOfferDetail.promotion} />
            </div>
          </>
        )}
        {saveOfferDetail.type === SaveOfferType.SWITCH_PLAN && saveOfferDetail.switchToPlan && (
          <SwitchPlanTypeContent
            effectiveDate={saveOfferDetail?.effectiveDate || EMPTY}
            switchToPlanTitle={`${saveOfferDetail.switchToPlan.pid} ${saveOfferDetail.switchToPlan.title} / ${saveOfferDetail.switchToPlan.billingPlanName}`}
            promotion={saveOfferDetail.promotion}
          />
        )}
        {saveOfferDetail.type === SaveOfferType.PAUSE && (
          <div className={styles.detailSection}>
            <div className={`${styles.sectionContent} ${styles.full}`}>
              <ItemField label="Save offer type:" value="Pause" />
            </div>
          </div>
        )}
        {saveOfferDetail.type === SaveOfferType.EXTEND_BILLING_DATE && (
          <div className={styles.detailSection}>
            <div className={`${styles.sectionContent} ${styles.full}`}>
              <ItemField label="Save offer type:" value="Extend billing date" />
            </div>
            <Divider />
            <div className={`${styles.sectionContent} ${styles.full}`}>
              <ItemField label="Extend days:" value={saveOfferDetail?.extendDays?.toString() || EMPTY} />
            </div>
          </div>
        )}
      </Card>
      {isDeleteModalShowing && (
        <EComModal
          title="Delete save offer"
          okText="confirm"
          visible={isDeleteModalShowing}
          onCancel={() => {
            setIsDeleteModalShowing(false);
          }}
          onOk={onDelete}
          confirmLoading={isDeleting}
        >
          Are you sure you want to delete the save offer?
        </EComModal>
      )}
    </>
  );
}
