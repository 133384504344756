import React, { useState } from "react";
import { FormInstance } from "antd/es/form";
import styles from "./ProductUpsellForm.module.scss";
import { SectionOneShotUpgradeInfo } from "./SectionOneShotUpgradeInfo";
import { SectionNami } from "./SectionNami";
import { SectionUpsellInfo } from "./SectionUpsellInfo";

function ProductUpsellForm({ form }: { form: FormInstance }) {
  const productUpsells = form.getFieldValue("productUpsells");
  const [showNamiSection, setShowNamiSection] = useState(productUpsells?.length > 0);

  return (
    <div className={styles.container}>
      <SectionUpsellInfo
        form={form}
        onRemove={(fieldsLength) => fieldsLength <= 1 && setShowNamiSection(false)}
        onAdd={() => setShowNamiSection(true)}
      />

      {showNamiSection && <SectionNami form={form} />}

      <SectionOneShotUpgradeInfo form={form} />
    </div>
  );
}

export default ProductUpsellForm;
