import React, { useState } from "react";
import { Dropdown, Menu } from "antd";
import Icon from "@ant-design/icons";
import * as Icons from "../../../../assets/icons";
import { EComProductStatus } from "../../../../types/model/eComProduct";
import useAppAuth0 from "../../../../hooks/useAppAuth0";

interface props {
  onEdit: () => void;
  onDelete: () => void;
  onReadyForPublish?: () => void;
  onRevertToDraft?: () => void;
  onActivate?: () => void;
  onDeactivate?: () => void;
  onPreviewCheckout?: () => void;
  status: string;
}

function PlanActions({
  onEdit,
  onDelete,
  onReadyForPublish,
  onRevertToDraft,
  onActivate,
  onDeactivate,
  onPreviewCheckout,
  status,
}: props) {
  const { user } = useAppAuth0();
  const [menuVisible, setMenuVisible] = useState(false);

  function ActionsMenu() {
    const displayMenuItem = () => {
      switch (status) {
        case EComProductStatus.ReadyForPublish:
          return (
            <>
              {user.canPreviewEcomPlanCheckout && (
                <Menu.Item key="checkout" onClick={onPreviewCheckout}>
                  Preview Checkout
                </Menu.Item>
              )}
              {user.canRevertEcomPlanToDraft && (
                <Menu.Item key="revertToDraft" onClick={onRevertToDraft}>
                  Revert to Draft
                </Menu.Item>
              )}
              {user.canDeleteEcomPlan && (
                <Menu.Item key="delete" onClick={onDelete}>
                  Delete
                </Menu.Item>
              )}
            </>
          );
        case EComProductStatus.Active:
          return (
            <>
              {user.canPreviewEcomPlanCheckout && (
                <Menu.Item key="checkout" onClick={onPreviewCheckout}>
                  Preview Checkout
                </Menu.Item>
              )}
              {user.canDeactivateEcomPlan && (
                <Menu.Item key="deactivate" onClick={onDeactivate}>
                  Deactivate
                </Menu.Item>
              )}
            </>
          );
        case EComProductStatus.Draft:
          return (
            <>
              {user.canReadyForPublishEcomPlan && (
                <Menu.Item key="readyForPublish" onClick={onReadyForPublish}>
                  Ready for publish
                </Menu.Item>
              )}
              {user.canDeleteEcomPlan && (
                <Menu.Item key="delete" onClick={onDelete}>
                  Delete
                </Menu.Item>
              )}
            </>
          );
        case EComProductStatus.Inactive:
          return (
            user.canActivateEcomPlan && (
              <Menu.Item key="activate" onClick={onActivate}>
                Activate
              </Menu.Item>
            )
          );
        default:
          return (
            user.canDeleteEcomPlan && (
              <Menu.Item key="delete" onClick={onDelete}>
                Delete
              </Menu.Item>
            )
          );
      }
    };
    return (
      <Menu
        onClick={() => setMenuVisible(false)}
        style={{
          fontSize: "15px",
          borderRadius: "4px",
          boxShadow: "3px 3px 12px 0px rgba(0, 35, 90, 0.16)",
        }}
      >
        {user.canUpdateEcomPlan && (
          <Menu.Item key="edit" onClick={onEdit}>
            Edit
          </Menu.Item>
        )}
        {displayMenuItem()}
      </Menu>
    );
  }

  return (
    <Dropdown
      overlay={<ActionsMenu />}
      placement="bottomRight"
      trigger={["click"]}
      visible={menuVisible}
      onVisibleChange={(visible) => setMenuVisible(visible)}
    >
      <Icon component={Icons.More} style={{ fontSize: 24 }} />
    </Dropdown>
  );
}

export default PlanActions;
