import React, { useState } from "react";
import toUpper from "lodash/toUpper";
import toInteger from "lodash/toInteger";
import { Col, DatePicker, Divider, Form, FormInstance, Input, InputNumber, Radio, Row, Select } from "antd";
import { SelectValue } from "antd/es/select";
import { InfoCircleOutlined } from "@ant-design/icons";
import { DropDown } from "../../../../../assets/icons";
import styles from "./index.module.scss";
import PromotionCodeText from "./PromotionCodeText";
import { DATETIME_FORMAT_WITH_MINUTE, PROMOTION_BULK_QUANTITY } from "../../../../../constants/common";
import { PromotionCodeUsageType, PromotionStatus, RecurlyCouponModel } from "../../../../../types/model/promotionV2";
import { BasePriceModel } from "../../../../../types/model/price";
import { Option } from "./index";
import { preventNonNumericalInput } from "../../../../../utils/preventNonNumericalInput";
import { getDisplayPromotionCodeUsage, getPromotionCodeLabel } from "../../util";

interface PromotionDetailsFormForRecurlySourceProps {
  form: FormInstance;
  recurlyCoupons: RecurlyCouponModel[];
  basePriceList: BasePriceModel[];
  countryList: Option[];
  handlePromotionCodeChange: (selectedRecurlyCouponCode: SelectValue) => void;
  isEditing: boolean;
}

const PromotionDetailsFormForRecurlySource = ({
  form,
  recurlyCoupons,
  basePriceList,
  countryList,
  handlePromotionCodeChange,
  isEditing,
}: PromotionDetailsFormForRecurlySourceProps) => {
  const [promotionCodeUsage, setPromotionCodeUsage] = useState<PromotionCodeUsageType>(
    form.getFieldValue("codeUsage") || PromotionCodeUsageType.REGULAR
  );
  const [promotionQuantity, setPromotionQuantity] = useState(PROMOTION_BULK_QUANTITY);
  const { RangePicker } = DatePicker;

  const getDisplayRecurlyCouponCode = (code: string, status: PromotionStatus): string => {
    return `${toUpper(code)} ${status === PromotionStatus.EXPIRED ? "(Expired)" : ""}`;
  };

  const promotionCodeUsageChangeHandler = (codeUsage: PromotionCodeUsageType) => {
    setPromotionCodeUsage(codeUsage);
    form.setFieldsValue({
      codeUsage,
      quantity: undefined,
    });
  };

  const validQuantity = () => {
    const quantity = form.getFieldValue("quantity");
    if (!quantity) {
      return Promise.reject("'Quantity' is required");
    }
    if (toInteger(quantity) > promotionQuantity) {
      return Promise.reject(`'Quantity' cannot be longer than ${promotionQuantity}`);
    }
    return Promise.resolve();
  };

  const PromotionCodeUsageTooltip = {
    title: <div>Single Use: one code valid for one redemption.</div>,
    icon: <InfoCircleOutlined />,
  };

  const PromotionQuantityTooltip = {
    title: <div>Maximum Number Of Redemptions: {promotionQuantity}</div>,
    icon: <InfoCircleOutlined />,
  };

  const promotionCodeChangeHandler = (selectedRecurlyCouponId: SelectValue) => {
    handlePromotionCodeChange(selectedRecurlyCouponId);
    const selectedRecurlyCoupon = recurlyCoupons.find(({ id }) => id === selectedRecurlyCouponId);
    setPromotionQuantity(Math.min(selectedRecurlyCoupon!.maxRedemptions, PROMOTION_BULK_QUANTITY));
  };

  return (
    <>
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item
            label="Promotion code usage"
            name="codeUsage"
            rules={[{ required: true }]}
            initialValue={PromotionCodeUsageType.REGULAR}
            tooltip={PromotionCodeUsageTooltip}
          >
            <Radio.Group onChange={(e) => promotionCodeUsageChangeHandler(e.target.value)} disabled={isEditing}>
              {Object.values(PromotionCodeUsageType).map((codeUsage) => (
                <Radio value={codeUsage} key={codeUsage}>
                  {getDisplayPromotionCodeUsage(codeUsage)}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
        {promotionCodeUsage === PromotionCodeUsageType.BULK && (
          <Col span={12}>
            <Form.Item
              label="Quantity"
              name="quantity"
              className={styles.inputNumber}
              rules={[
                {
                  required: true,
                  validator: validQuantity,
                },
              ]}
              tooltip={PromotionQuantityTooltip}
            >
              <InputNumber
                style={{ width: "100%" }}
                precision={0}
                step={1}
                onKeyPress={preventNonNumericalInput}
                disabled={isEditing}
              />
            </Form.Item>
          </Col>
        )}
      </Row>

      <Row gutter={32}>
        <Col span={12}>
          <Form.Item
            label={getPromotionCodeLabel(form.getFieldValue("codeUsage"))}
            name="displayPromotionCode"
            rules={[{ required: true }]}
          >
            <Select
              optionFilterProp="label"
              getPopupContainer={(trigger) => trigger.parentNode}
              showArrow
              showSearch
              suffixIcon={<DropDown />}
              placeholder="Please select a promotion code"
              options={recurlyCoupons.map(({ id, code, status }) => ({
                label: getDisplayRecurlyCouponCode(code, status),
                value: id,
              }))}
              onChange={(e) => promotionCodeChangeHandler(e)}
              disabled={isEditing && promotionCodeUsage === PromotionCodeUsageType.BULK}
            />
          </Form.Item>
          <Form.Item noStyle shouldUpdate name="recurlyCouponId">
            <Input hidden />
          </Form.Item>
          <Form.Item noStyle shouldUpdate name="code">
            <Input hidden />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Promotion name" name="name" rules={[{ required: true }]}>
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Time range" name="timeRange" rules={[{ required: true }]} validateTrigger={["onChange"]}>
            <RangePicker
              disabled
              style={{ width: "100%", backgroundColor: "#f5f5f5", borderColor: "#d9d9d9" }}
              showTime={{ format: "HH:mm" }}
              format={DATETIME_FORMAT_WITH_MINUTE}
              placeholder={["", "-"]}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Promotion type" name="type" rules={[{ required: true }]}>
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Discount" name="discount" rules={[{ required: true }]}>
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Status" name="status" rules={[{ required: true }]}>
            <Input disabled />
          </Form.Item>
        </Col>
        <PromotionCodeText />
      </Row>

      <Divider className={styles.divider} />

      <Row gutter={32}>
        <Col span={24}>
          <Form.Item label="Applied base price" name="basePriceIds" rules={[{ required: true, type: "array" }]}>
            <Select
              allowClear
              optionFilterProp="label"
              getPopupContainer={(trigger) => trigger.parentNode}
              showArrow
              suffixIcon={<DropDown />}
              mode="multiple"
              placeholder="Please select applied base price"
              options={basePriceList.map(({ id, name }) => ({
                label: name,
                value: id,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="countryIds" label="Applied country/region">
            <Select
              allowClear
              optionFilterProp="label"
              getPopupContainer={(trigger) => trigger.parentNode}
              showArrow
              suffixIcon={<DropDown />}
              mode="multiple"
              placeholder="Please select applied country/region"
              options={countryList}
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider className={styles.divider} />
    </>
  );
};

export default PromotionDetailsFormForRecurlySource;
