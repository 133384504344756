export enum Connection {
  APPLE = "APPLE",
  FACEBOOK = "FACEBOOK",
  GOOGLE = "GOOGLE",
  USERNAME = "USERNAME",
  OKTA = "OKTA",
}

export interface CustomerResponse {
  customerId: number;

  auth0Id: string;
  // Connection
  connection?: Connection;
  // Organization
  organization?: string;
  // Create At
  createdDate: number;
  // Customer Email
  email: string;
  // Customer Name
  name: string;

  supportResetPassword: boolean;

  sharedCustomers: any[];
}

export interface CustomerInfoResponse {
  auth0Id: string;

  connection?: Connection;

  createdDate: number;

  customerGroup?: string;

  customerId: number;

  email: string;

  name: string;

  organization?: string;

  subUsers?: string[];

  supportResetPassword: boolean;

  rootUserName?: string;

  sharedCustomers: any[];
}

interface giftUser {
  first_name: string;
  last_name: string;
  email: string;
}

export enum GiftStatus {
  PENDING = "Pending",
  SENT = "Sent",
  REDEEMED = "Redeemed",
}

interface GiftDetail {
  id: string;
  gift_info: {
    title: string;
  };
  status: GiftStatus;
  delivery_date: string;
  giver: giftUser;
  receiver: giftUser;
  redeemed_at?: string;
  redeemer_email?: string;
  subscription_start_date?: string;
  subscription_end_date?: string;
}

export interface GiftResponse {
  given: GiftDetail[];
  received: GiftDetail[];
}
