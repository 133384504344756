import { CountryInfo } from "./eComProduct";
import { ContentBase } from "./description";
import { LocaleType } from "../../utils/locale";

export interface PromotionModel {
  id: string;
  updatedAt: string;
  name: string;
  code: string;
  startAt: string;
  endAt: string;
  discountType: DiscountType;
  percentageDiscount?: number;
  currencyCode?: string;
  amountDiscount?: number | null;
  status: PromotionStatus;
  basePrices?: {
    id: string;
    name: string;
  }[];
  promotionCodeTexts?: {
    [LocaleType.English]: string;
    [LocaleType.Spanish]: string;
  };
  codeUsage: CodeUsageType;
  quantity?: number;
}

export interface PromotionDetailModel extends PromotionModel {
  billingCycle?: number;
  isRestrictToNew?: boolean;
  isSubscription?: boolean;
  isStackable?: boolean;
  countries?: CountryInfo[];
  isActiveBefore: boolean;
  descriptionDisclosures?: DescriptionDisclosure[];
  basePlan?: PlanModal;
  giftPlan?: PlanModal;
}

export enum CodeUsageType {
  REGULAR = "regular",
  SINGLE_USE = "single use",
}

interface PlanModal {
  id: string;
  pid: string;
  title: string;
  billingPlanId: string;
  billingPlanName: string;
}

export enum DiscountType {
  PERCENTAGE = "percentage off",
  AMOUNT = "amount off",
  BUY_ONE_GIFT_ONE = "buy one gift one",
}

export interface PromotionListModel {
  list: PromotionModel[];
  total: number;
}

export enum PromotionStatus {
  ACTIVE = "active",
  NOT_STARTED = "not started",
  EXPIRED = "expired",
}

export enum PromotionStatusRecurly {
  NOT_STARTED = "not_started",
  REDEEMABLE = "redeemable",
  EXPIRED = "expired",
  MAXED_OUT = "maxed_out",
}

export interface DescriptionDisclosure {
  billingPlanId?: string;
  billingPlanName?: string;
  description?: ContentBase[];
  disclosure?: ContentBase[];
}

export interface PromotionFormData {
  name: string;
  timeRange: [string, string];
  code: string;
  isSubscription: boolean;
  isStackable: boolean;
  countryIds?: number[];
  isRestrictToNew?: boolean;
  billingCycle?: number;
  descriptionDisclosures?: DescriptionDisclosure[];
  discountType: DiscountType;
  percentageDiscount?: number;
  currencyCode?: string;
  amountDiscount?: number;
  basePriceIds: string[];
  basePlanId?: string;
  giftPlanId?: string;
  codeTextEn?: string;
  codeTextEs?: string;
  quantity?: number;
  codeUsage: CodeUsageType;
}

export enum SingleUseStatus {
  REDEEMED = "Redeemed",
  UNREDEEMED = "Unredeemed",
  NOT_STARTED = "Not Started",
  EXPIRED = "Expired",
}

export interface PromotionSingleUseListModel {
  list: PromotionSingleUseModel[];
  redeemedCount: number;
}

export interface PromotionSingleUseModel {
  code: string;
  status: SingleUseStatus;
  email?: string;
  customerId?: string;
  date?: string;
}
