import { ProductStatus, ProductType, ProductTypeCapital } from "types/model/product";
import { OptionType } from "../types/helper";
import { Connection } from "../types/dto/response/customer";

export const DEFAULT_PAGINATION = {
  page: 0,
  size: 10,
};

export const DEFAULT_SORTER = "createdDate,DESC";

export const DEFAULT_MAX_PAGINATION = {
  page: 0,
  size: 10000,
};

export const initialPageRecords = {
  content: [],
  size: 10,
  totalPages: 0,
  totalElements: 0,
  contentSize: 0,
  page: 1,
};

export const EMPTY = "--";

export const DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const DATETIME_FORMAT_WITH_MINUTE = "YYYY-MM-DD HH:mm";
export const DAY_FORMAT = "YYYY-MM-DD";
export const DATETIME_FORMAT_WITH_00_SECOND = "YYYY-MM-DDTHH:mm:00Z";

export const CONNECTION_MAP: Map<string, string> = new Map([
  ["USERNAME", "Username"],
  ["GOOGLE", "Google"],
  ["APPLE", "Apple"],
  ["FACEBOOK", "Facebook"],
  ["OKTA", "Okta"],
]);

export const PRODUCT_STATUS_VIEW_MAP: Map<ProductStatus, string> = new Map([
  ["ACTIVE", "Active"],
  ["STAGING", "Staging"],
  ["READY_FOR_REVIEW", "In Review"],
  ["ARCHIVED", "Archived"],
]);

export const PRODUCT_TYPE_VIEW_MAPPING: Record<ProductType, ProductTypeCapital> = {
  DVD: "DVD",
  FREE: "Free",
  LESSON_PACK: "Lesson pack",
  MULTI_LEVEL: "Multi-level",
  SINGLE_LEVEL: "Single level",
  SUBSCRIPTION: "Subscription",
  UPGRADE: "Upgrade",
  UPSELL: "Upsell",
  ALL_ACCESS: "All Access",
};

export const DAY_BEGIN = { hour: 0, minute: 0, second: 0, millisecond: 0 };
export const DAY_END = { hour: 23, minute: 59, second: 59, millisecond: 999 };

export const SEPARATOR = " | ";

export const LEAVE_CONFIRMATION = "All changes will be lost if you leave this page without saving.";

export const VOUCHER_TYPE_MAP: Map<string, string> = new Map([
  ["SINGLE", "Single"],
  ["MASTER", "Master"],
  ["SEAT", "Seat"],
]);

export const DEAL_MAP: Map<string, string> = new Map([
  ["OPEN_ENDED", "Open ended"],
  ["FIXED_TERM", "Fixed term"],
]);

export const ENTITLEMENT_DEAL_MAP: Map<string, string> = new Map([
  ["OPEN_ENDED", "Open ended"],
  ["FIXED_TERM", "Fixed term"],
  ["FIXED_ENDED", "Fixed expiration date"],
]);

export const ISBN_SELECTION_MAP: Map<string, string> = new Map([
  ["GENERIC", "Generic ISBN"],
  ["INDIVIDUAL", "Individual ISBNs"],
]);

/*eslint no-template-curly-in-string:*/
export const validateMessages = {
  required: "'${label}' is required",
  string: {
    len: "Must be exactly ${len} characters",
    max: "Must be less than ${max} characters",
  },
  number: {
    min: "Must be more than ${min}",
    range: "Must be between ${min} and ${max}",
  },
  types: {
    email: "'${label}' is not a valid ${type}",
  },
};

export const ENTITLEMENT_SOURCE_MAP: Map<string, string> = new Map([
  ["ADMIN", "Admin"],
  ["IOS_APPSTORE", "App store"],
  ["ECOM", "eCom"],
  ["GOOGLEPLAY", "Google play"],
  ["DEMANDWARE", "SFCC"],
  ["MG2", "Naviga"],
  ["ECOM_MG2", "eCom Naviga"],
  ["ECOM_GIFT", "eCom Gift"],
  ["B2B_REDEMPTION", "Redemption"],
  ["B2B_SEAT_ASSIGNMENT", "Seat"],
  ["UNLIMITED_ACTIVATION", "Unlimited"],
  ["EDT_SSDS", "SSDS"],
  ["OKTA", "Okta"],
  ["AMAZON", "Amazon"],
]);

export const ENTITLEMENT_STATUS_MAP: Map<string, string> = new Map([
  ["ACTIVE", "Active"],
  ["EXPIRED", "Expired"],
  ["CANCELED", "Canceled"],
  ["PAUSED", "Paused"],
  ["REVOKED", "Revoked"],
]);

export const IAP_PURCHASE_STATUS_MAP: Map<string, string> = new Map([
  ["PURCHASE_PROCESS_STATUS_SUBSCRIPTION_EXPIRED", "Expired"],
  ["PURCHASE_PROCESS_STATUS_FINALIZED", "Finalized"],
  ["PURCHASE_PROCESS_STATUS_INTENT", "Intent"],
  ["PURCHASE_PROCESS_STATUS_CANCELED", "Canceled"],
  ["PURCHASE_PROCESS_STATUS_FAILED", "Failed"],
]);

export const connectionTypeOptions: OptionType<Connection>[] = [
  { label: "Username", value: Connection.USERNAME },
  { label: "Google", value: Connection.GOOGLE },
  { label: "Apple", value: Connection.APPLE },
  { label: "Facebook", value: Connection.FACEBOOK },
  { label: "Okta", value: Connection.OKTA },
];

export const nonZeroHundredthDecimalValidator = /^(?!0\.00)\d+(\.\d{0,2})?$/;

export const oneDecimalPlaceValidator = /^\d*(\.\d{0,1})?$/;

export const NO_REASON_SELECTED_CANCEL_REASON_CODE = 9999;

export const PROMOTION_BULK_QUANTITY = 10000;
