import React, { useEffect, useState } from "react";
import { Prompt } from "react-router";
import { Button, Divider, Form, FormProps, Input, Radio, Select, Space } from "antd";
import Icon from "@ant-design/icons";
import { FormInstance } from "antd/es/form";
import { VoucherGroupCreateRequest } from "types/dto/request/voucher";
import moment from "moment";
import { DAY_BEGIN, DAY_END, LEAVE_CONFIRMATION, validateMessages } from "constants/common";
import { InputNumber } from "components";
import { DropDown, Minus, Plus } from "assets/icons";
import { DealType, IsbnSelection, VoucherType } from "types/model/voucher";
import { VoucherGroupDetailResponse } from "types/dto/response/voucher";
import useVoucherOptions from "hooks/useVoucherOptions";
import { span } from "utils/style";
import styles from "./VoucherForm.module.scss";
import { preventNonNumericalInput } from "../../../utils/preventNonNumericalInput";
import StartDateInput from "./StartDateInput";
import EndDateInput from "./EndDateInput";
import EntitlementDeal from "./EntitlementDeal";

export type VoucherFormValues = VoucherGroupCreateRequest & {
  languageAndCourse?: Array<{
    language: string | undefined;
    course: number[] | undefined;
  }>;
} & { domains?: string[] };

export interface VoucherFormProps extends Pick<FormProps<VoucherFormValues>, "onFinish" | "onFinishFailed"> {
  isEditing?: boolean;
  data?: VoucherGroupDetailResponse;
  form: FormInstance<VoucherFormValues>;
}

function VoucherForm({ isEditing, data, form, ...otherProps }: VoucherFormProps) {
  const { onFinish, onFinishFailed } = otherProps;
  const { options, getIndividualCourseOptions } = useVoucherOptions();
  const [submitted, setSubmitted] = useState(false);

  const initialLanguageAndCourse = data?.languageProductGroups?.map((item) => ({
    language: item.languageName,
    course: item.productItems?.map((product) => product.productId),
  })) ?? [{}];

  useEffect(() => {
    window.onbeforeunload = () => true;
    return () => {
      window.onbeforeunload = null;
    };
  });

  useEffect(() => form.resetFields(), [form]);

  const handleLanguageChange = (index: number) => {
    const { languageAndCourse } = form.getFieldsValue();
    languageAndCourse && (languageAndCourse[index].course = undefined);
    form.setFieldsValue({
      languageAndCourse: languageAndCourse,
    });
  };

  const handleVoucherTypeChange = () => {
    form.resetFields([
      ["voucherInfo", "voucherAmount"],
      ["voucherInfo", "usesPerUser"],
    ]);
  };

  const handleIsbnSelectionChange = () => {
    const { voucherGroupCourse } = form.getFieldsValue();

    if (voucherGroupCourse?.isbnSelectionType === "GENERIC") {
      form.resetFields(["languageAndCourse"]);
    } else {
      form.setFieldsValue({
        voucherGroupCourse: {
          ...voucherGroupCourse,
          genericProductIsbn: undefined,
        },
      });
    }
  };

  const validDateRange = () => {
    const { validPeriod } = form.getFieldsValue();
    if (!validPeriod?.startDate || !validPeriod?.expirationDate) {
      return Promise.reject("'Valid duration' is required");
    } else {
      return Promise.resolve();
    }
  };

  return (
    <>
      <Prompt when={!submitted} message={LEAVE_CONFIRMATION} />
      <Form
        {...otherProps}
        form={form}
        layout={"inline"}
        labelAlign={"left"}
        validateMessages={validateMessages}
        validateTrigger={["onBlur", "onSubmit"]}
        onFinish={(values) => {
          setSubmitted(true);
          onFinish && onFinish(values);
        }}
        onFinishFailed={(values) => {
          setSubmitted(false);
          onFinishFailed && onFinishFailed(values);
        }}
        className={styles.form}
        onValuesChange={(changedValues, allValues) => {
          const expirationDate = changedValues.validPeriod?.expirationDate;
          const voucherDeal = changedValues.voucherDeal;
          const entitlementDeal = changedValues.entitlementDeal;

          if (expirationDate !== undefined) {
            form.setFieldsValue({ entitlementExpirationDate: expirationDate });
          }

          if (voucherDeal === "FIXED_TERM" || entitlementDeal === "FIXED_ENDED") {
            form.setFieldsValue({
              entitlementExpirationDate: data?.validPeriod?.expirationDate ?? moment().add(1, "year").valueOf(),
            });
          }
        }}
      >
        <Form.Item
          label="Vouchers description"
          name="description"
          initialValue={data?.description}
          rules={[{ required: true, type: "string", max: 100 }]}
          style={span(12)}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Organization"
          name="organizationId"
          initialValue={data?.organizationId}
          rules={[{ required: true }]}
          style={span(12)}
        >
          <Select
            disabled={isEditing}
            allowClear
            showSearch={true}
            listHeight={160}
            getPopupContainer={(trigger) => trigger.parentNode}
            suffixIcon={<DropDown />}
            placeholder={"Please choose organization"}
            optionFilterProp={"label"}
            options={options.organization}
          />
        </Form.Item>
        <Form.Item<VoucherType>
          label="Voucher type"
          name={["voucherInfo", "voucherType"]}
          initialValue={data?.voucherInfo?.voucherType ?? "SINGLE"}
          rules={[{ required: true }]}
          style={span(12)}
        >
          <Radio.Group onChange={handleVoucherTypeChange} disabled={isEditing} options={options.voucherType} />
        </Form.Item>
        <Form.Item<DealType>
          label="Voucher deal"
          name="voucherDeal"
          initialValue={data?.voucherDeal ?? "FIXED_TERM"}
          rules={[{ required: true }]}
          style={span(6)}
        >
          <Radio.Group options={options.DealType} />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {() =>
            form.getFieldValue("voucherDeal") === "FIXED_TERM" && (
              <>
                <Form.Item
                  label="Valid duration"
                  required
                  name="period"
                  rules={[
                    {
                      validator: validDateRange,
                    },
                  ]}
                  style={span(6)}
                >
                  <Space size={0}>
                    <Form.Item
                      noStyle
                      initialValue={data?.validPeriod?.startDate ? data?.validPeriod?.startDate : moment().valueOf()}
                      name={["validPeriod", "startDate"]}
                    >
                      <StartDateInput expirationDate={form.getFieldValue(["validPeriod", "expirationDate"])} />
                    </Form.Item>
                    <span style={{ margin: "0 2px" }}>-</span>
                    <Form.Item
                      noStyle
                      initialValue={
                        data?.validPeriod?.expirationDate
                          ? data?.validPeriod?.expirationDate
                          : moment().add(1, "year").valueOf()
                      }
                      name={["validPeriod", "expirationDate"]}
                    >
                      <EndDateInput startDate={form.getFieldValue(["validPeriod", "startDate"])} />
                    </Form.Item>
                  </Space>
                </Form.Item>
              </>
            )
          }
        </Form.Item>

        <Divider style={{ margin: "8px 0 32px 0" }} />

        <Form.Item<IsbnSelection>
          label="ISBN selection"
          name={["voucherGroupCourse", "isbnSelectionType"]}
          initialValue={data?.isbnSelectionType ?? "GENERIC"}
          rules={[{ required: true }]}
          style={span(9)}
        >
          <Radio.Group onChange={handleIsbnSelectionChange} options={options.isbnSelection} />
        </Form.Item>
        <Form.Item noStyle shouldUpdate={true}>
          {(formInstance) =>
            formInstance.getFieldsValue()?.voucherGroupCourse?.isbnSelectionType === "GENERIC" && (
              <>
                <Form.Item style={span(3)} />
                <Form.Item
                  label="Price per voucher"
                  name={["price", "amount"]}
                  initialValue={data?.price?.amount}
                  rules={[{ required: true, type: "number", min: 0 }]}
                  style={span(6)}
                >
                  <InputNumber onKeyPress={preventNonNumericalInput} />
                </Form.Item>
                <Form.Item
                  label="Currency"
                  name={["price", "currency"]}
                  initialValue={data?.price?.currency ?? "USD"}
                  rules={[{ type: "enum", enum: ["USD"] }]}
                  style={span(6)}
                >
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item
                  label="Course"
                  name={["voucherGroupCourse", "genericProductIsbn"]}
                  initialValue={data?.genericProduct?.isbn}
                  rules={[{ required: true }]}
                  style={span(24)}
                >
                  <Select
                    allowClear
                    showSearch
                    listHeight={160}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    suffixIcon={<DropDown />}
                    placeholder={"Please choose course"}
                    optionFilterProp={"label"}
                    options={options.genericProduct}
                  />
                </Form.Item>
              </>
            )
          }
        </Form.Item>
        <Form.Item noStyle shouldUpdate={true}>
          {(formInstance) =>
            formInstance.getFieldsValue()?.voucherGroupCourse?.isbnSelectionType === "INDIVIDUAL" && (
              <>
                <Form.Item style={span(15)} />
                <Form.List name="languageAndCourse" initialValue={initialLanguageAndCourse}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <React.Fragment key={index}>
                          <Form.Item
                            label={index === 0 && "Language"}
                            name={[field.name, "language"]}
                            rules={[
                              {
                                required: true,
                                message: "'Language' is required",
                              },
                            ]}
                            fieldKey={[field.fieldKey, "language"]}
                            style={span(6)}
                          >
                            <Select
                              allowClear
                              showSearch
                              listHeight={160}
                              optionFilterProp={"label"}
                              getPopupContainer={(trigger) => trigger.parentNode}
                              suffixIcon={<DropDown />}
                              placeholder={"Please choose language"}
                              onChange={() => handleLanguageChange(index)}
                              options={options.language}
                            />
                          </Form.Item>
                          <Form.Item
                            label={index === 0 && "Course"}
                            name={[field.name, "course"]}
                            rules={[
                              {
                                required: true,
                                message: "'Course' is required",
                              },
                            ]}
                            fieldKey={[field.fieldKey, "course"]}
                            style={span(17)}
                          >
                            <Select
                              allowClear
                              showSearch
                              mode="multiple"
                              suffixIcon={<DropDown />}
                              listHeight={160}
                              getPopupContainer={(trigger) => trigger.parentNode}
                              onChange={(value) => {
                                const { voucherGroupCourse } = form.getFieldsValue();
                                const selectedProductIds = Array.from(
                                  new Set([...(voucherGroupCourse?.selectedProductIds ?? []), value as number])
                                );
                                form.setFieldsValue({
                                  voucherGroupCourse: {
                                    ...voucherGroupCourse,
                                    selectedProductIds: selectedProductIds,
                                  },
                                });
                              }}
                              optionFilterProp={"label"}
                              placeholder={"Please choose course"}
                              options={getIndividualCourseOptions(
                                form?.getFieldsValue()?.languageAndCourse?.[index]?.language
                              )}
                              virtual={false}
                            />
                          </Form.Item>
                          {field.key > 0 && (
                            <Form.Item style={span(1)}>
                              <Icon component={Minus} onClick={() => remove(field.name)} />
                            </Form.Item>
                          )}
                        </React.Fragment>
                      ))}
                      <Button
                        onClick={() => add()}
                        icon={<Icon component={Plus} />}
                        type="link"
                        className={styles.button}
                      >
                        Add new language
                      </Button>
                    </>
                  )}
                </Form.List>
              </>
            )
          }
        </Form.Item>

        <Divider style={{ margin: "8px 0 32px 0" }} />

        <Form.Item<VoucherFormValues>
          noStyle
          shouldUpdate={(prev, curr) => prev?.voucherInfo?.voucherType !== curr?.voucherInfo?.voucherType}
        >
          {() => (
            <Form.Item<VoucherFormValues>
              label="Total licenses"
              name={["voucherInfo", "voucherAmount"]}
              initialValue={data?.voucherInfo?.voucherAmount}
              shouldUpdate={(prev, curr) => prev?.voucherInfo?.voucherType !== curr?.voucherInfo?.voucherType}
              rules={[{ required: true, type: "number", min: 1, max: 10000 }]}
              style={span(6)}
            >
              <InputNumber type={"integer"} disabled={isEditing} onKeyPress={preventNonNumericalInput} />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item<VoucherFormValues> noStyle shouldUpdate={(prev, curr) => prev?.voucherInfo !== curr?.voucherInfo}>
          {(formInstance) => {
            const { voucherInfo } = formInstance.getFieldsValue();
            const voucherType = voucherInfo?.voucherType ?? "SINGLE";
            const voucherAmount = voucherInfo?.voucherAmount;
            return (
              voucherType === "MASTER" && (
                <Form.Item<VoucherFormValues>
                  label="Total licenses per user"
                  name={["voucherInfo", "usesPerUser"]}
                  initialValue={data?.voucherInfo?.usesPerUser}
                  rules={
                    voucherAmount
                      ? [
                          {
                            type: "number",
                            min: 1,
                            max: voucherAmount,
                          },
                          { required: true },
                        ]
                      : [{ required: true }]
                  }
                  style={span(6)}
                >
                  <InputNumber type={"integer"} onKeyPress={preventNonNumericalInput} />
                </Form.Item>
              )
            );
          }}
        </Form.Item>

        <EntitlementDeal form={form} data={data} />

        <Divider style={{ margin: "8px 0 32px 0" }} />

        <Form.Item
          required
          label="Payment type"
          name="isPrepaidVoucher"
          initialValue={data?.isPrepaidVoucher ?? true}
          style={span(6)}
        >
          <Radio.Group options={options.isPrepaidVoucher} />
        </Form.Item>
        <Form.Item
          label="Invoice number"
          name="invoice"
          initialValue={data?.invoice}
          rules={[{ type: "string", max: 255 }]}
          style={span(6)}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Doc number"
          name="docNumber"
          initialValue={data?.docNumber}
          rules={[{ type: "string", max: 255 }]}
          style={span(12)}
        >
          <Input />
        </Form.Item>
      </Form>
    </>
  );
}

export function getProductIds(data: VoucherFormValues["languageAndCourse"]) {
  if (data == null) {
    return undefined;
  }
  const productIds = data.map((item) => item?.course ?? []).reduce((prev, curr) => [...prev, ...curr], []);
  return Array.from(new Set(productIds));
}

export function toVoucherGroupCreateRequest(values: VoucherFormValues) {
  const { languageAndCourse, domains, ...data } = values;
  data.voucherGroupCourse.selectedProductIds = getProductIds(languageAndCourse);
  if (data.validPeriod != null) {
    data.validPeriod = {
      startDate: moment(data.validPeriod.startDate).set(DAY_BEGIN).valueOf(),
      expirationDate: moment(data.validPeriod.expirationDate).set(DAY_END).valueOf(),
    };
  }
  if (data.entitlementExpirationDate != null) {
    data.entitlementExpirationDate = moment(data.entitlementExpirationDate).set(DAY_END).valueOf();
  }
  return data;
}

export default VoucherForm;
