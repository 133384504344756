import { capitalize, replace, upperFirst } from "lodash";
import { supportLocalTabs } from "../../../utils/locale";
import { ContentBase } from "../../../types/model/description";
import {
  Currency,
  PromotionCodeUsageType,
  PromotionDiscountType,
  PromotionDuration,
  PromotionDurationType,
  PromotionStatus,
  SubscriptionPromotion,
} from "../../../types/model/promotionV2";
import { EMPTY } from "../../../constants/common";

const toTitleCase = (str: string) => capitalize(replace(str.toLowerCase(), /_/g, " "));

export const getDisplayPromotionDiscountType = (type: PromotionDiscountType) => {
  if (Object.values(PromotionDiscountType).includes(type)) {
    return toTitleCase(type);
  }
  return undefined;
};

export const getDisplayPromotionStatus = (status?: PromotionStatus) => {
  if (status && Object.values(PromotionStatus).includes(status)) {
    return toTitleCase(status);
  }
  return undefined;
};

export const getDisplayPromotionCodeUsage = (promotionCodeUsage?: PromotionCodeUsageType) => {
  switch (promotionCodeUsage) {
    case PromotionCodeUsageType.REGULAR:
      return "Regular";
    case PromotionCodeUsageType.BULK:
      return "Single Use";
    default:
      return EMPTY;
  }
};

export const getBulkPromotionDisplayCode = (code: string, codeUsage?: PromotionCodeUsageType) =>
  codeUsage === PromotionCodeUsageType.BULK ? `${code}******` : code;

export const getPromotionCodeLabel = (codeUsage?: PromotionCodeUsageType) =>
  codeUsage === PromotionCodeUsageType.BULK ? "Promotion code prefix" : "Promotion code";

export const getDisplayDiscount = (
  discountType: PromotionDiscountType,
  percent?: number,
  currencies?: Currency[]
): string => {
  return discountType === PromotionDiscountType.PERCENTAGE_OFF
    ? `${percent!.toFixed(2)}%`
    : currencies!.map(({ currency, amount }) => `${currency} ${amount.toFixed(2)}`).join(" / ");
};

export const getDiscount = (selectedPromotion?: SubscriptionPromotion) => {
  if (!selectedPromotion) {
    return EMPTY;
  }

  return getDisplayDiscount(selectedPromotion.discountType, selectedPromotion.percent, selectedPromotion.currencies);
};

export const getDisplayDuration = (duration?: PromotionDuration) => {
  switch (duration?.type) {
    case PromotionDurationType.SINGLE_USE:
      return "Single use";
    case PromotionDurationType.FOREVER:
      return "Forever";
    case PromotionDurationType.TEMPORAL:
      const durationAmount = duration.limitTime?.amount;
      const durationUnit = duration.limitTime?.unit;
      return durationAmount === 1
        ? `${durationAmount} ${upperFirst(durationUnit)}`
        : `${durationAmount} ${upperFirst(durationUnit)}s`;
    default:
      return EMPTY;
  }
};

export const transformDescriptionDisclosure = (values: any, contentKey: string) => {
  return Object.keys(values)
    .filter((key) => key.includes(contentKey))
    .map((key) => {
      const locale = key.split(contentKey)[0];
      return {
        ...supportLocalTabs.find((item) => item.key === locale),
        required: false,
        content: values[key],
      } as ContentBase;
    });
};
