import { ColumnType } from "antd/es/table";
import moment from "moment";
import {
  PromotionCodeUsageType,
  PromotionDiscountType,
  PromotionModel,
  PromotionStatus,
} from "../../../../types/model/promotionV2";
import { DATETIME_FORMAT_WITH_MINUTE, EMPTY } from "../../../../constants/common";
import CustomerDot from "../../../../components/CustomDot";
import {
  getBulkPromotionDisplayCode,
  getDisplayPromotionCodeUsage,
  getDisplayPromotionDiscountType,
  getDisplayPromotionStatus,
} from "../util";

export default function getPromotionTableColumns(): ColumnType<PromotionModel>[] {
  return [
    {
      title: "Promotion name",
      key: "name",
      dataIndex: "name",
      width: 220,
      fixed: "left",
      ellipsis: true,
      render: (text: string) => text,
    },
    {
      title: "Applied base price",
      key: "basePrices",
      width: 220,
      ellipsis: true,
      render: (_, { basePrices, discountType }) => {
        if (discountType === PromotionDiscountType.BUY_ONE_GIFT_ONE) {
          return EMPTY;
        }
        return basePrices?.map((basePrice) => basePrice.name).join(", ");
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: 125,
      ellipsis: true,
      render: (status: PromotionStatus) => {
        return <CustomerDot color={getDisplayPromotionStatus(status)} />;
      },
    },
    {
      title: "Code usage",
      key: "codeUsage",
      dataIndex: "codeUsage",
      width: 120,
      ellipsis: true,
      render: (text: PromotionCodeUsageType) => getDisplayPromotionCodeUsage(text),
    },
    {
      title: "Promotion code",
      key: "code",
      dataIndex: "code",
      width: 200,
      ellipsis: true,
      render: (code: string, { codeUsage }) => getBulkPromotionDisplayCode(code, codeUsage),
    },
    {
      title: "Promotion Type",
      key: "discountType",
      dataIndex: "discountType",
      width: 150,
      ellipsis: true,
      render: (discountType: PromotionDiscountType) => getDisplayPromotionDiscountType(discountType),
    },
    {
      title: "Discount",
      key: "discount",
      dataIndex: "discountType",
      width: 120,
      ellipsis: true,
      render: (discountType: PromotionDiscountType, record: PromotionModel) => {
        if (discountType === PromotionDiscountType.PERCENTAGE_OFF && record.percentageDiscount) {
          return `${record.percentageDiscount}%`;
        } else if (discountType === PromotionDiscountType.AMOUNT_OFF && record.amountDiscount && record.currencyCode) {
          return `${record.currencyCode} ${record.amountDiscount}`;
        }
        return EMPTY;
      },
    },
    {
      title: "Time range",
      key: "timeRange",
      dataIndex: "startAt",
      width: 312,
      ellipsis: true,
      render: (_value: string, record: PromotionModel) => {
        const localStart = moment(record.startAt).local().format(DATETIME_FORMAT_WITH_MINUTE);
        const localEnd = record.endAt ? moment(record.endAt).local().format(DATETIME_FORMAT_WITH_MINUTE) : EMPTY;
        return `${localStart} to ${localEnd}`;
      },
    },
  ];
}
